import React from 'react';
import LinkifyIt from 'linkify-it';
const linkifyIt = LinkifyIt();
export const LinkifyText = ({ text, style }) => {
    const matches = linkifyIt.match(text);
    if (matches === null)
        return React.createElement("div", null, text);
    const txtLinksSpliced = [text.slice(0, matches[0].index)].concat(matches.map((lnk, idx) => {
        const end = idx + 1 >= matches.length ? undefined : matches[idx + 1].index;
        return text.slice(lnk.lastIndex, end);
    }));
    return (React.createElement("div", { style: style }, txtLinksSpliced.map((txt, idx) => React.createElement(React.Fragment, { key: 'linkified_' + idx },
        React.createElement("span", null, txt),
        idx < matches.length &&
            React.createElement("a", { href: matches[idx].url, target: "_blank" }, matches[idx].text)))));
};
