import React, { Component } from 'react';
import CartIcon from '../buttons/CartIcon';
import PreviewIcon from '../buttons/PreviewIcon';
import { formatBytes, formatDateWithOptionalTime, pick, getUrlWithEnvironmentPrefix } from '../../utils';
import config, { timezone } from '../../config';
import { previewAvailability } from '../../models/Preview';
import CollectionBtn from '../buttons/CollectionBtn';
export default class SearchResultCompactRow extends Component {
    handlePreviewClick() {
        this.props.handlePreview([this.props.objInfo.dobj]);
    }
    render() {
        const props = this.props;
        const objInfo = props.objInfo;
        const previewItem = props.preview.item;
        const className = previewItem && previewItem.dobj === objInfo.dobj
            ? "list-group-item-info"
            : "";
        const size = parseInt(objInfo.size);
        return React.createElement("tr", { className: className },
            React.createElement("td", { className: 'text-nowrap' },
                props.user.email &&
                    React.createElement(CartIcon, { style: { marginRight: 10 }, objInfo: objInfo, ...pick(props, 'addToCart', 'removeFromCart', 'isAddedToCart') }),
                React.createElement(PreviewIcon, { style: { marginRight: 10 }, preview: previewAvailability(props.lookup, objInfo), clickAction: this.handlePreviewClick.bind(this) }),
                React.createElement(CollectionLinks, { extendedDobjInfo: props.extendedDobjInfo, dobj: objInfo.dobj }),
                React.createElement("a", { title: "View metadata", href: getUrlWithEnvironmentPrefix(objInfo.dobj) }, objInfo.fileName)),
            React.createElement("td", { className: 'text-nowrap' }, formatBytes(size, 0)),
            React.createElement("td", null, formatDateWithOptionalTime(new Date(objInfo.submTime), timezone[config.envri].offset)),
            React.createElement("td", null, formatDateWithOptionalTime(new Date(objInfo.timeStart), timezone[config.envri].offset)),
            React.createElement("td", null, formatDateWithOptionalTime(new Date(objInfo.timeEnd), timezone[config.envri].offset)));
    }
}
const CollectionLinks = ({ extendedDobjInfo, dobj }) => {
    if (extendedDobjInfo === undefined || extendedDobjInfo[0] === undefined)
        return null;
    const dois = extendedDobjInfo.find(eDobjInfo => eDobjInfo.dobj === dobj)?.dois;
    if (dois === undefined)
        return null;
    return (React.createElement(React.Fragment, null, dois.map((doi, i) => React.createElement(CollectionBtn, { key: i, url: config.doiBaseUrl + doi, title: `Part of collection ${doi}`, iconStyle: { marginLeft: 10, marginRight: 13 } }))));
};
