import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeQuerySubmittable, updateFileName, updateSearchOption, updateSelectedPids } from "../../actions/search";
import FilterByPid from "../../components/filters/FilterByPid";
import CheckBtn from "../../components/buttons/CheckBtn";
import { FilterPanel } from "../../components/filters/FilterPanel";
import { ToSparqlClient } from '../../components/ToSparqlClient';
import { publicQueries } from '../../config';
import * as queries from '../../sparqlQueries';
import { getFilters } from '../../actions/common';
import { specKeywordsQuery } from '../../backend/keywordsInfo';
import FilterByFileName from "../../components/filters/FilterByFileName";
class Advanced extends Component {
    render() {
        const { searchOptions, filterPids, filterFileName, updateSearchOption, updateSelectedPids, updateFileName, getPublicQuery } = this.props;
        const { showDeprecated } = searchOptions;
        return (React.createElement(React.Fragment, null,
            React.createElement(FilterPanel, { header: "Text filters" },
                React.createElement(FilterByPid, { filterPids, filterFileName, updateSelectedPids, showDeprecated }),
                React.createElement(FilterByFileName, { showDeprecated, updateFileName, filterFileName })),
            React.createElement(FilterPanel, { header: "Search options" },
                React.createElement(CheckButton, { onClick: () => updateSearchOption({ name: 'showDeprecated', value: !showDeprecated }), isChecked: showDeprecated, text: 'Show deprecated objects' })),
            React.createElement(FilterPanel, { header: "SPARQL queries", helpItemName: "publicQuery" },
                React.createElement(QueryList, { getPublicQuery: getPublicQuery }))));
    }
}
const QueryList = ({ getPublicQuery }) => {
    return (React.createElement("ul", { style: { marginTop: 10, listStyle: 'none', padding: 5 } }, Object.keys(publicQueries).map((queryName, idx) => {
        const { label, comment } = publicQueries[queryName];
        return (React.createElement("li", { key: idx },
            React.createElement(ToSparqlClient, { queryName: queryName, getPublicQuery: getPublicQuery, label: label, comment: comment })));
    })));
};
const CheckButton = (props) => {
    const { onClick, isChecked, text } = props;
    const checkboxDisabled = props.checkboxDisabled || false;
    const defaultStyleBtn = { margin: 0, fontSize: 12 };
    const styleBtn = props.styleBtn
        ? Object.assign(defaultStyleBtn, props.styleBtn)
        : defaultStyleBtn;
    const defaultStyleTxt = { marginLeft: 5 };
    const styleTxt = props.styleTxt
        ? { ...defaultStyleTxt, ...props.styleTxt }
        : defaultStyleTxt;
    return (React.createElement("div", { style: { marginTop: 15 } },
        React.createElement("label", null,
            React.createElement(CheckBtn, { onClick: onClick, isChecked: isChecked, style: styleBtn, checkboxDisabled: checkboxDisabled }),
            React.createElement("span", { style: styleTxt }, text))));
};
function getQueryBuilder(state) {
    const lookup = {
        specBasics: queries.specBasics,
        specColumnMeta: queries.specColumnMeta,
        dobjOriginsAndCounts: () => queries.dobjOriginsAndCounts(getFilters(state)),
        extendedDataObjectInfo: () => queries.extendedDataObjectInfo(state.extendedDobjInfo.map(d => d.dobj)),
        labelLookup: queries.labelLookup,
        specKeywordsQuery: specKeywordsQuery
    };
    return qName => makeQuerySubmittable(lookup[qName]().text);
}
;
function stateToProps(state) {
    return {
        filterPids: state.filterPids,
        filterFileName: state.filterFileName,
        searchOptions: state.searchOptions,
        getPublicQuery: getQueryBuilder(state)
    };
}
function dispatchToProps(dispatch) {
    return {
        updateSelectedPids: (pids) => dispatch(updateSelectedPids(pids)),
        updateFileName: (name) => dispatch(updateFileName(name)),
        updateSearchOption: (searchOption) => dispatch(updateSearchOption(searchOption))
    };
}
export default connect(stateToProps, dispatchToProps)(Advanced);
