import React from 'react';
import config, { placeholders } from "../../config";
import HelpButton from "../../containers/help/HelpButton";
import MultiSelectFilter from "./MultiSelectFilter";
import FilterOperationBtn from "../buttons/FilterOperationBtn";
const search = {}; //values are set by MultiSelectFilter
export const MultiselectCtrl = props => {
    const { name, data, value, helpItem, updateFilter } = props;
    const shouldUseExternalListEntry = helpItem?.shouldUseExternalList ?? false;
    const isInverterEnabled = value.length > 0 && data.length > 0 && name !== "variable" && name !== "valType";
    const placeholder = data.length === 1
        ? `${data[0].text}`
        : `(${data.length} items)`;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row", style: { marginTop: 10 } },
            React.createElement("div", { className: "col d-flex justify-content-between" },
                React.createElement("div", null,
                    React.createElement("label", null, placeholders[config.envri][name]),
                    React.createElement(HelpButton, { name: name })),
                React.createElement("div", null,
                    React.createElement(FilterOperationBtn, { enabled: isInverterEnabled, filterName: name, title: "Invert filter selection", baseStyle: { fontSize: 16 }, iconCls: "fas fa-retweet", action: () => updateFilter(name, data.map(item => item.value)) }),
                    React.createElement(FilterOperationBtn, { enabled: value.length > 0, filterName: name, title: "Reset this filter", baseStyle: { fontSize: 16, marginLeft: 12 }, iconCls: "fas fa-times-circle", action: () => updateFilter(name, []) })))),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-md-12" },
                React.createElement(MultiSelectFilter, { name: name, shouldUseExternalListEntry: shouldUseExternalListEntry, search: search, updateFilter: updateFilter, placeholder: placeholder, data: data, value: value })))));
};
