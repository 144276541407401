import { tableFormatForSpecies } from "icos-cp-backend";
export default class TableFormatCache {
    config;
    cache = {};
    constructor(config) {
        this.config = config;
    }
    getTableFormat(specUri) {
        const current = this.cache[specUri];
        if (current)
            return current;
        const newTf = tableFormatForSpecies(specUri, this.config);
        this.cache[specUri] = newTf;
        return newTf;
    }
    isInCache(specUri) {
        return this.cache[specUri] !== undefined;
    }
}
