import { sparql } from 'icos-cp-backend';
export function sparqlFetchAndParse(query, sparqlEndpoint, parser) {
    return sparql(query, sparqlEndpoint, true)
        .then((sparqlRes) => {
        try {
            return {
                colNames: sparqlRes.head.vars,
                rows: sparqlRes.results.bindings.map(parser)
            };
        }
        catch (err) {
            let message = (err instanceof Error) ? err.message : 'unspecified error';
            throw new Error("Failed to parse SPARQL response: " + message);
        }
    });
}
;
export function sparqlFetch(queryTxt, sparqlEndpoint, sparqlResponseType, acceptCachedResults) {
    const getType = () => {
        switch (sparqlResponseType) {
            case 'JSON': return 'application/json';
            case 'CSV': return 'text/csv';
            case 'XML': return 'application/xml';
            case 'TSV or Turtle': return 'text/plain';
        }
    };
    const cacheHeader = acceptCachedResults
        ? {} //expecting default cache behaviour from the server
        : { 'Cache-Control': 'no-cache' };
    const headers = {
        'Accept': getType(),
        'Content-Type': 'text/plain'
    };
    return fetch(sparqlEndpoint, {
        method: 'post',
        headers: new Headers({ ...cacheHeader, ...headers }),
        body: queryTxt
    })
        .then(resp => {
        if (resp.ok) {
            return resp;
        }
        else {
            return resp.text().then(txt => Promise.reject(new Error(txt || resp.statusText || "Ajax response status: " + resp.status)));
        }
    });
}
