import React from "react";
import keywordsInfo from "../../backend/keywordsInfo";
import { isDefined } from "../../utils";
import FilterOperationBtn from "../buttons/FilterOperationBtn";
import MultiSelectFilter from "./MultiSelectFilter";
export const KeywordFilter = props => {
    const { keywords, filterKeywords, setKeywordFilter } = props;
    const value = filterKeywords.map(kw => ({ text: kw, value: kw, helpStorageListEntry: [] }));
    const data = keywordsInfo.allKeywords(keywords)
        .map(txt => ({ text: txt, value: txt, helpStorageListEntry: [] }))
        .filter(item => !value.some(v => v.value == item.value));
    const placeholder = data.length === 1
        ? `${data[0]}`
        : `(${data.length} items)`;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row", style: { marginTop: 10 } },
            React.createElement("div", { className: "col d-flex justify-content-between" },
                React.createElement("div", null,
                    React.createElement("label", null, "Keyword")),
                React.createElement("div", null,
                    React.createElement(FilterOperationBtn, { enabled: value.length > 0, filterName: "keywordFilter", title: "Reset this filter", baseStyle: { fontSize: 16, marginLeft: 12 }, iconCls: "fas fa-times-circle", action: () => setKeywordFilter([]) })))),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-md-12" },
                React.createElement(MultiSelectFilter, { name: "keywordFilter", shouldUseExternalListEntry: false, search: {}, updateFilter: (_, keywords) => setKeywordFilter(keywords.filter(isDefined).map(kw => kw + '')), placeholder: placeholder, data: data, value: value })))));
};
