import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers/mainReducer';
import { init } from './actions/main';
import stateUtils from "./models/State";
import { ActionPayload } from "./reducers/actionpayloads";
const logStoreChange = (currentState, nextState, changes, select) => {
    const historyState = history.state ? select(history.state) : undefined;
    console.log({ currentState, nextState, changes, historyState, historyLength: history.state ? history.length : 0 });
};
const payloadMiddleware = store => next => action => {
    const finalAction = (action instanceof ActionPayload)
        ? createAction(action)
        : action;
    return next(finalAction);
};
const opts = window.location.hostname.startsWith('local-')
    ? { trace: true }
    : {};
const REDUX_DEVTOOLS_EXTENSION_COMPOSE = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];
const composeEnhancers = REDUX_DEVTOOLS_EXTENSION_COMPOSE
    ? REDUX_DEVTOOLS_EXTENSION_COMPOSE(opts)
    : compose;
const enhancer = composeEnhancers(applyMiddleware(payloadMiddleware, thunkMiddleware));
export default function () {
    const store = createStore(reducer, stateUtils.getStateFromHash(), enhancer);
    store.dispatch(init);
    // Use storeOverwatch to log changes in store
    // stateUtils.storeOverwatch(store, ['specTable', 'paging'], logStoreChange);
    return store;
}
function createAction(payload) {
    return {
        type: payload.constructor.name,
        payload
    };
}
