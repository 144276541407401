import React, { Component } from 'react';
export default class Tabs extends Component {
    tabState;
    constructor(props) {
        super(props);
        const selectedTabId = props.selectedTabId ?? 0;
        this.tabState = props.children.map((child, idx) => ({
            id: idx,
            tabHeader: child.props.tabHeader,
            isActive: idx === selectedTabId
        }));
    }
    componentWillUpdate(nextProps) {
        this.setActiveTab(nextProps.selectedTabId);
    }
    setActiveTab(tabId = 0) {
        this.tabState.forEach(tab => tab.isActive = tab.id === tabId);
    }
    onTabClick(selectedTabId) {
        const { tabName, switchTab } = this.props;
        if (tabName && switchTab)
            switchTab(tabName, selectedTabId);
    }
    render() {
        const { children } = this.props;
        const border = '1px solid #ddd';
        const tabContentStyle = {
            borderLeft: border,
            borderRight: border,
            borderBottom: border
        };
        return (React.createElement("div", { className: "card" },
            React.createElement("div", { className: "card-header" },
                React.createElement("ul", { className: "nav nav-tabs card-header-tabs" }, this.tabState.map(tab => React.createElement("li", { key: 'tabHeader' + tab.id, className: 'nav-item' },
                    React.createElement("a", { style: { cursor: 'pointer' }, className: tab.isActive ? 'nav-link active' : 'nav-link', onClick: this.onTabClick.bind(this, tab.id) }, tab.tabHeader))))),
            React.createElement("div", { className: "tab-content card-body p-0" },
                React.createElement("div", { className: "tab-pane active" }, children.find((child, idx) => this.tabState[idx].isActive)))));
    }
}
