import React from 'react';
const defaultIconStyle = {
    color: 'black',
    fontSize: 13
};
export default function CollectionBtn({ url, openInNewTab = true, iconStyle = {}, title }) {
    const icon = React.createElement("span", { className: "fas fa-folder-open", style: { ...defaultIconStyle, ...iconStyle }, title: title ?? "Part of collection" });
    if (url === undefined)
        return icon;
    return (React.createElement("a", { href: url, target: openInNewTab ? '_blank' : '_self' }, icon));
}
