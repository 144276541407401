import React, { Component } from 'react';
import { iframeEmbedSize } from '../../config';
import HelpButton from '../../containers/help/HelpButton';
import CopyValue from '../controls/CopyValue';
export default class PreviewControls extends Component {
    render() {
        const { iframeUrl, previewType, csvDownloadUrl, chartType, chartTypeAction, showChartTypeControl } = this.props;
        const { width, height } = iframeEmbedSize[previewType];
        const codeToEmbed = `<iframe width="${width}" height="${height}" frameborder="0" src="${iframeUrl}"></iframe>`;
        return (React.createElement("div", { className: "col-auto ms-auto d-flex gap-2" },
            chartType && chartTypeAction && showChartTypeControl &&
                React.createElement("div", { className: "btn-group", role: "group", "aria-label": "Toggle between scatter and line charts" },
                    React.createElement("input", { type: "radio", className: "btn-check", name: "chart-type", id: "scatter-chart", autoComplete: "off", onChange: () => chartTypeAction('line'), checked: chartType == 'scatter' }),
                    React.createElement("label", { className: "btn btn-outline-secondary d-flex align-items-center", htmlFor: "scatter-chart", title: "View scatter chart" },
                        React.createElement("svg", { viewBox: "0 0 512 512", xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: 'currentColor', className: 'me-2' },
                            React.createElement("path", { d: "m288 288c17.674 0 32-14.328 32-32 0-17.674-14.326-32-32-32s-32 14.326-32 32c0 17.672 14.326 32 32 32zm96 64c17.674 0 32-14.328 32-32 0-17.674-14.326-32-32-32s-32 14.326-32 32c0 17.672 14.326 32 32 32zm32-192c17.674 0 32-14.328 32-32 0-17.674-14.326-32-32-32s-32 14.326-32 32c0 17.672 14.326 32 32 32zm72 272h-440v-376c0-13.256-10.746-24-24-24s-24 10.744-24 24v392c0 17.6 14.4 32 32 32h456c13.254 0 24-10.746 24-24 0-13.256-10.746-24-24-24zm-296-240c17.674 0 32-14.328 32-32 0-17.674-14.326-32-32-32s-32 14.326-32 32c0 17.672 14.326 32 32 32zm-32 160c17.674 0 32-14.328 32-32 0-17.674-14.326-32-32-32s-32 14.326-32 32c0 17.672 14.326 32 32 32z" })),
                        "Scatter"),
                    React.createElement("input", { type: "radio", className: "btn-check", name: "chart-type", id: "line-chart", autoComplete: "off", onChange: () => chartTypeAction('scatter'), checked: chartType == 'line' }),
                    React.createElement("label", { className: "btn btn-outline-secondary d-flex align-items-center", htmlFor: "line-chart", title: "View line chart" },
                        React.createElement("svg", { viewBox: "0 0 512 512", xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: 'currentColor', className: 'me-2' },
                            React.createElement("path", { d: "m144.953 304.953 63.047-63.031 79.047 79.031c4.672 4.672 10.812 7.047 16.953 7.047s12.281-2.344 16.953-7.047l152.018-151.986c9.375-9.375 9.371-24.576-.008-33.946-9.371-9.365-24.561-9.361-33.928.008l-135.035 135.018-79.053-79.006c-9.375-9.371-24.574-9.367-33.947.006l-80 80c-9.375 9.375-9.375 24.562 0 33.953 9.391 9.375 24.578 9.344 33.953-.047zm343.047 127.047h-440v-376c0-13.25-10.75-24-24-24s-24 10.75-24 24v392c0 17.594 14.406 32 32 32h456c13.25 0 24-10.75 24-24s-10.75-24-24-24z" })),
                        "Line")),
            React.createElement("a", { className: 'btn btn-outline-secondary', href: iframeUrl },
                React.createElement("i", { className: "fas fa-expand-arrows-alt", title: "View full screen" })),
            React.createElement("details", { className: "dropdown-details" },
                React.createElement("summary", { className: 'summary-button' },
                    "Use preview data ",
                    React.createElement("i", { className: 'fas fa-chevron-down px-1', style: { fontSize: '0.8rem' } })),
                React.createElement("div", { className: 'dropdown', style: { width: 360, right: 0 } },
                    React.createElement("div", { className: 'p-3 border-bottom' },
                        React.createElement("p", null, "Embed preview"),
                        React.createElement(EmbedPreview, { url: codeToEmbed })),
                    csvDownloadUrl &&
                        React.createElement("div", { className: 'p-3' },
                            React.createElement("div", { className: 'mb-2' },
                                React.createElement("span", null, "Access preview data as CSV"),
                                React.createElement("span", { className: 'float-end' },
                                    React.createElement(HelpButton, { name: "previewCsvDownload", title: "Click to toggle help", overrideStyles: { padding: 5 } }))),
                            React.createElement("div", { className: 'mb-2 text-muted', style: { fontSize: "0.875rem" } }, "For advanced use, such as parsing the data with a program. Please read the help for more information."),
                            React.createElement(CsvDownloadCopyValue, { downloadUrl: csvDownloadUrl }))))));
    }
}
const EmbedPreview = ({ url }) => {
    if (url === '')
        return null;
    return (React.createElement("div", { style: { display: 'block', whiteSpace: 'nowrap' } },
        React.createElement(CopyValue, { btnText: "Copy", copyHelpText: "Click to copy the code to embed the preview", valToCopy: url })));
};
const CsvDownloadCopyValue = ({ downloadUrl }) => {
    if (downloadUrl === '')
        return null;
    return (React.createElement("div", { style: { display: 'block', whiteSpace: 'nowrap' } },
        React.createElement(CopyValue, { btnText: "Copy preview CSV download URL", copyHelpText: "Click to copy download URL to clipboard", valToCopy: downloadUrl })));
};
