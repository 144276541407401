import React, { Component } from 'react';
export default class CopyValue extends Component {
    urlInput;
    copyUrl() {
        if (this.urlInput)
            this.urlInput.select();
        document.execCommand('copy');
        this.setState({ showCopy: false });
    }
    render() {
        const { copyHelpText, valToCopy, helpButtonName } = this.props;
        return (React.createElement("span", { className: 'fs-6' },
            React.createElement(CopyCtr, { self: this, valToCopy: valToCopy, copyHelpText: copyHelpText, copyClick: this.copyUrl.bind(this) })));
    }
}
const CopyCtr = ({ self, valToCopy, copyHelpText, copyClick }) => {
    const inputClick = () => {
        if (self.urlInput)
            self.urlInput.select();
    };
    return (React.createElement("span", { className: "input-group" },
        React.createElement("input", { ref: urlInput => self.urlInput = urlInput, onClick: inputClick, type: "text", className: "form-control form-control-sm", value: valToCopy, readOnly: true }),
        React.createElement("button", { className: "btn btn-outline-secondary", style: { borderColor: '#ced4da' }, onClick: copyClick, title: copyHelpText },
            React.createElement("span", { className: "fas fa-copy" }))));
};
