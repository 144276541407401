import backendReducer from './backendReducer';
import miscReducer from './miscReducer';
import previewReducer from './previewReducer';
import uiReducer from './uiReducer';
import filtersReducer from './filtersReducer';
import { BackendPayload, MiscPayload, PreviewPayload, UiPayload, FiltersPayload, BootstrapRoutePayload } from "./actionpayloads";
import { defaultState } from "../models/State";
import bootstrapRouteReducer from "./bootstrapRouteReducer";
const reducer = (state = defaultState, action) => {
    const payload = action.payload;
    if (payload instanceof BootstrapRoutePayload)
        return bootstrapRouteReducer(state, payload);
    if (payload instanceof BackendPayload)
        return backendReducer(state, payload);
    if (payload instanceof MiscPayload)
        return miscReducer(state, payload);
    if (payload instanceof PreviewPayload)
        return previewReducer(state, payload);
    if (payload instanceof UiPayload)
        return uiReducer(state, payload);
    if (payload instanceof FiltersPayload)
        return filtersReducer(state, payload);
    return state;
};
export default reducer;
