import React, { Component } from 'react';
import { addingToCartProhibition } from '../../models/CartItem';
import { styles } from '../styles';
export default class CartIcon extends Component {
    constructor(props) {
        super(props);
    }
    handleAddToCartClick() {
        const { objInfo, addToCart } = this.props;
        if (addToCart)
            addToCart([objInfo.dobj]);
    }
    handleRemoveFromCartClick() {
        const { objInfo, removeFromCart } = this.props;
        if (removeFromCart)
            removeFromCart([objInfo.dobj]);
    }
    render() {
        const { isAddedToCart, objInfo, style } = this.props;
        const { allowCartAdd, uiMessage } = addingToCartProhibition(objInfo);
        return (React.createElement("span", { style: style }, allowCartAdd
            ? isAddedToCart
                ? React.createElement("span", { style: styles.clickIcon, title: "Remove from data cart", className: "fas fa-minus-circle text-danger", onClick: this.handleRemoveFromCartClick.bind(this) })
                : React.createElement("span", { style: styles.clickIcon, title: "Add to data cart", className: "fas fa-plus-circle text-primary", onClick: this.handleAddToCartClick.bind(this) })
            : React.createElement("span", { style: styles.disabledClickIcon, title: uiMessage, className: "fas fa-plus-circle text-muted" })));
    }
}
