import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNumberFilter, setFilterTemporal, specFilterUpdate, setKeywordFilter } from "../../actions/search";
import { PanelsWithFilters } from "../../components/filters/PanelsWithFilters";
class Filters extends Component {
    render() {
        const { specTable, filterTemporal, helpStorage, labelLookup, updateFilter, handleFilterReset, setFilterTemporal, filterPids, setNumberFilter, filterNumbers, keywords, filterKeywords, setKeywordFilter, countryCodesLookup, spatialRects } = this.props;
        const resetBtnEnabled = filterTemporal.hasFilter
            || specTable.hasActiveFilters
            || filterNumbers.hasFilters
            || filterKeywords.length > 0
            || filterPids !== null
            || (!!spatialRects && spatialRects.length > 0);
        return (React.createElement("div", null,
            React.createElement(ResetBtn, { enabled: resetBtnEnabled, resetFiltersAction: handleFilterReset }),
            React.createElement(PanelsWithFilters, { filterNumbers: filterNumbers, specTable: specTable, helpStorage: helpStorage, labelLookup: labelLookup, countryCodesLookup: countryCodesLookup, updateFilter: updateFilter, setNumberFilter: setNumberFilter, filterTemporal: filterTemporal, setFilterTemporal: setFilterTemporal, keywords: keywords, filterKeywords: filterKeywords, setKeywordFilter: setKeywordFilter, startCollapsed: false })));
    }
}
const ResetBtn = ({ resetFiltersAction, enabled }) => {
    const onClick = () => enabled ? resetFiltersAction() : {};
    const baseStyle = { margin: '7px', fontSize: 14 };
    const style = enabled
        ? { ...baseStyle, cursor: 'pointer' }
        : { ...baseStyle, opacity: 0.5 };
    const title = enabled ? "Reset all filters" : "No active filters";
    return (React.createElement("div", { className: "d-flex justify-content-end" },
        React.createElement("span", { className: "fa-stack fa-1x", onClick: onClick, title: title, style: style },
            React.createElement("i", { className: "fas fa-filter fa-stack-1x" }),
            React.createElement("i", { className: "fas fa-ban fa-stack-2x" }))));
};
function stateToProps(state) {
    return {
        filterTemporal: state.filterTemporal,
        filterNumbers: state.filterNumbers,
        specTable: state.specTable,
        helpStorage: state.helpStorage,
        labelLookup: state.labelLookup,
        countryCodesLookup: state.countryCodesLookup,
        keywords: state.keywords,
        filterKeywords: state.filterKeywords,
        filterPids: state.filterPids,
        spatialRects: state.mapProps.rects
    };
}
function dispatchToProps(dispatch) {
    return {
        updateFilter: (varName, values) => dispatch(specFilterUpdate(varName, values)),
        setFilterTemporal: (filterTemporal) => dispatch(setFilterTemporal(filterTemporal)),
        setNumberFilter: (numberFilter) => dispatch(setNumberFilter(numberFilter)),
        setKeywordFilter: (filterKeywords) => dispatch(setKeywordFilter(filterKeywords)),
    };
}
export default connect(stateToProps, dispatchToProps)(Filters);
