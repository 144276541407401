import React, { Component } from 'react';
import PreviewBtn from './buttons/PreviewBtn';
import EditablePanelHeading from './controls/EditablePanelHeading';
import SearchResultRegularRow from './searchResult/SearchResultRegularRow';
import CartBtn from './buttons/CartBtn';
import CheckAllBoxes from './controls/CheckAllBoxes';
export default class CartPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItemId: props.previewitemId
        };
    }
    handleItemClick(id) {
        this.setState({ selectedItemId: id });
    }
    handleSaveCartName(newName) {
        if (this.props.setCartName)
            this.props.setCartName(newName);
    }
    handlePreview(ids) {
        this.props.previewItemAction(ids);
    }
    handleAllCheckboxesChange() {
        this.props.handleAllCheckboxesChange();
    }
    render() {
        const props = this.props;
        const objectText = props.checkedObjectsInCart.length <= 1 ? "object" : "objects";
        const checkedObjects = props.cart.items
            .filter(item => props.checkedObjectsInCart.includes(item.dobj));
        return (React.createElement("div", { className: "card" },
            React.createElement(EditablePanelHeading, { editValue: props.cart.name, saveValueAction: this.handleSaveCartName.bind(this), iconEditClass: "fas fa-edit", iconEditTooltip: "Edit download name", iconSaveClass: "fas fa-save", iconSaveTooltip: "Save new cart name" }),
            React.createElement("div", { className: "card-body" },
                React.createElement("div", { className: "panel-srollable-controls d-flex justify-content-between flex-wrap" },
                    React.createElement("div", { className: "d-flex mb-2" },
                        React.createElement(CheckAllBoxes, { checkCount: props.checkedObjectsInCart.length, totalCount: props.cart.count, onChange: this.props.handleAllCheckboxesChange }),
                        props.checkedObjectsInCart.length > 0 &&
                            React.createElement("span", { style: { margin: "7px 10px" } },
                                props.checkedObjectsInCart.length,
                                " ",
                                objectText,
                                " selected")),
                    React.createElement("div", { className: "d-flex mb-3" },
                        React.createElement(PreviewBtn, { style: {}, checkedObjects: checkedObjects, previewLookup: props.previewLookup, clickAction: this.handlePreview.bind(this) }),
                        React.createElement(CartBtn, { style: { marginLeft: 10 }, checkedObjects: props.checkedObjectsInCart, clickAction: props.removeFromCart, enabled: props.checkedObjectsInCart.length > 0, type: 'remove' }))),
                props.cart.items.map((objInfo, i) => {
                    const extendedInfo = props.extendedDobjInfo.find(ext => ext.dobj === objInfo.dobj);
                    if (extendedInfo === undefined)
                        return null;
                    const isChecked = props.checkedObjectsInCart.includes(objInfo.dobj);
                    return (React.createElement(SearchResultRegularRow, { labelLookup: props.labelLookup, extendedInfo: extendedInfo, preview: props.preview, objInfo: objInfo, key: 'dobj_' + i, updateCheckedObjects: props.updateCheckedObjects, isChecked: isChecked, isCartView: true }));
                }))));
    }
}
