export function getObjCount(specTable) {
    return specTable.origins.filteredRows.reduce((acc, next) => acc + toNumber(next.count), 0);
}
export function isInPidFilteringMode(tabs, filterPids) {
    return tabs.searchTab === 1 && filterPids !== null;
}
function toNumber(v) {
    if (v === undefined)
        return 0;
    if (typeof v === 'string')
        return parseInt(v);
    return v;
}
