export class ActionPayload {
}
export class BootstrapRoutePayload extends ActionPayload {
}
export class BackendPayload extends ActionPayload {
}
export class MiscPayload extends ActionPayload {
}
export class PreviewPayload extends ActionPayload {
}
export class UiPayload extends ActionPayload {
}
export class FiltersPayload extends ActionPayload {
}
export class BootstrapRouteSearch extends BootstrapRoutePayload {
    constructor() { super(); }
}
export class BootstrapRoutePreview extends BootstrapRoutePayload {
    pids;
    objectsTable;
    extendedDobjInfo;
    specTables;
    labelLookup;
    constructor(pids, objectsTable, extendedDobjInfo, specTables, labelLookup) {
        super();
        this.pids = pids;
        this.objectsTable = objectsTable;
        this.extendedDobjInfo = extendedDobjInfo;
        this.specTables = specTables;
        this.labelLookup = labelLookup;
    }
}
export class BootstrapRouteMetadata extends BootstrapRoutePayload {
    id;
    metadata;
    objectsTable;
    constructor(id, metadata, objectsTable) {
        super();
        this.id = id;
        this.metadata = metadata;
        this.objectsTable = objectsTable;
    }
}
export class BootstrapRouteCart extends BootstrapRoutePayload {
    extendedDobjInfo;
    objectsTable;
    labelLookup;
    constructor(extendedDobjInfo, objectsTable, labelLookup) {
        super();
        this.extendedDobjInfo = extendedDobjInfo;
        this.objectsTable = objectsTable;
        this.labelLookup = labelLookup;
    }
}
export class BackendUserInfo extends BackendPayload {
    user;
    profile;
    constructor(user, profile) {
        super();
        this.user = user;
        this.profile = profile;
    }
}
export class BootstrapInfo extends BackendPayload {
    info;
    constructor(info) {
        super();
        this.info = info;
    }
}
export class BackendOriginsTable extends BackendPayload {
    table;
    resetPaging;
    constructor(table, resetPaging = false) {
        super();
        this.table = table;
        this.resetPaging = resetPaging;
    }
}
export class BackendUpdateSpecFilter extends BackendPayload {
    varName;
    filter;
    constructor(varName, filter) {
        super();
        this.varName = varName;
        this.filter = filter;
    }
}
export class BackendObjectMetadataId extends BackendPayload {
    id;
    constructor(id) {
        super();
        this.id = id;
    }
}
export class BackendObjectMetadata extends BackendPayload {
    metadata;
    constructor(metadata) {
        super();
        this.metadata = metadata;
    }
}
export class BackendExtendedDataObjInfo extends BackendPayload {
    extendedDobjInfo;
    constructor(extendedDobjInfo) {
        super();
        this.extendedDobjInfo = extendedDobjInfo;
    }
}
export class BackendTsSettings extends BackendPayload {
    tsSettings;
    constructor(tsSettings) {
        super();
        this.tsSettings = tsSettings;
    }
}
export class BackendUpdateCart extends BackendPayload {
    cart;
    constructor(cart) {
        super();
        this.cart = cart;
    }
}
export class BackendBatchDownload extends BackendPayload {
    isBatchDownloadOk;
    user;
    constructor(isBatchDownloadOk, user) {
        super();
        this.isBatchDownloadOk = isBatchDownloadOk;
        this.user = user;
    }
}
export class BackendObjectsFetched extends BackendPayload {
    objectsTable;
    isDataEndReached;
    constructor(objectsTable, isDataEndReached) {
        super();
        this.objectsTable = objectsTable;
        this.isDataEndReached = isDataEndReached;
    }
}
export class BackendExportQuery extends BackendPayload {
    isFetchingCVS;
    sparqClientQuery;
    constructor(isFetchingCVS, sparqClientQuery) {
        super();
        this.isFetchingCVS = isFetchingCVS;
        this.sparqClientQuery = sparqClientQuery;
    }
}
export class MiscError extends MiscPayload {
    error;
    constructor(error) {
        super();
        this.error = error;
    }
}
export class MiscLoadError extends MiscPayload {
    state;
    cart;
    constructor(state, cart) {
        super();
        this.state = state;
        this.cart = cart;
    }
}
export class MiscRestoreFromHistory extends MiscPayload {
    historyState;
    constructor(historyState) {
        super();
        this.historyState = historyState;
    }
}
export class MiscResetFilters extends MiscPayload {
    constructor() { super(); }
}
export class MiscRestoreFilters extends MiscPayload {
    constructor() { super(); }
}
export class MiscUpdateSearchOption extends MiscPayload {
    newSearchOption;
    constructor(newSearchOption) {
        super();
        this.newSearchOption = newSearchOption;
    }
}
export class MiscUpdateMapProps extends MiscPayload {
    persistedMapProps;
    constructor(persistedMapProps) {
        super();
        this.persistedMapProps = persistedMapProps;
    }
}
export class MiscUpdateAddToCart extends MiscPayload {
    addToCart;
    constructor(addToCart) {
        super();
        this.addToCart = addToCart;
    }
}
export class RestorePreview extends PreviewPayload {
    constructor() { super(); }
}
export class SetPreviewFromCart extends PreviewPayload {
    ids;
    constructor(ids) {
        super();
        this.ids = ids;
    }
}
export class SetPreviewUrl extends PreviewPayload {
    url;
    constructor(url) {
        super();
        this.url = url;
    }
}
export class SetPreviewYAxis extends PreviewPayload {
    yAxis;
    constructor(yAxis) {
        super();
        this.yAxis = yAxis;
    }
}
export class SetPreviewY2Axis extends PreviewPayload {
    y2Axis;
    constructor(y2Axis) {
        super();
        this.y2Axis = y2Axis;
    }
}
export class UiToggleSorting extends UiPayload {
    varName;
    constructor(varName) {
        super();
        this.varName = varName;
    }
}
export class UiStepRequested extends UiPayload {
    direction;
    constructor(direction) {
        super();
        this.direction = direction;
    }
}
export class UiSwitchTab extends UiPayload {
    tabName;
    selectedTabId;
    constructor(tabName, selectedTabId) {
        super();
        this.tabName = tabName;
        this.selectedTabId = selectedTabId;
    }
}
export class UiUpdateHelpInfo extends UiPayload {
    helpItem;
    constructor(helpItem) {
        super();
        this.helpItem = helpItem;
    }
}
export class UiInactivateAllHelp extends UiPayload {
    constructor() { super(); }
}
export class UiUpdateCheckedObjsInSearch extends UiPayload {
    checkedObjectInSearch;
    constructor(checkedObjectInSearch) {
        super();
        this.checkedObjectInSearch = checkedObjectInSearch;
    }
}
export class UiUpdateCheckedObjsInCart extends UiPayload {
    checkedObjectInCart;
    constructor(checkedObjectInCart) {
        super();
        this.checkedObjectInCart = checkedObjectInCart;
    }
}
export class FiltersTemporal extends FiltersPayload {
    filterTemporal;
    constructor(filterTemporal) {
        super();
        this.filterTemporal = filterTemporal;
    }
}
export class FiltersNumber extends FiltersPayload {
    numberFilter;
    constructor(numberFilter) {
        super();
        this.numberFilter = numberFilter;
    }
}
export class FilterKeywords extends FiltersPayload {
    keywords;
    constructor(keywords) {
        super();
        this.keywords = keywords;
    }
}
export class FiltersUpdatePids extends FiltersPayload {
    selectedPids;
    constructor(selectedPids) {
        super();
        this.selectedPids = selectedPids;
    }
}
export class FiltersUpdateFileName extends FiltersPayload {
    fileName;
    constructor(fileName) {
        super();
        this.fileName = fileName;
    }
}
