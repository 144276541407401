import React from 'react';
const baseStyle = {
    transition: 'margin-right 0.5s ease-in-out',
    float: 'right'
};
export const SlideIn = ({ isOpen, width, children }) => {
    const marginRight = isOpen ? { marginRight: 0 } : { marginRight: -width };
    const style = { ...baseStyle, ...marginRight };
    return (React.createElement("div", { style: style }, children));
};
