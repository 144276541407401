import React from 'react';
import config from '../../config';
export default function HelpText(props) {
    const { helpItem } = props;
    if (helpItem === undefined)
        return null;
    const main = helpItem.main[config.envri] ?? helpItem.main;
    return helpItem
        ? (React.createElement("div", { style: { fontSize: '90%' } },
            React.createElement("p", null, main),
            React.createElement(List, { list: helpItem.list }),
            React.createElement(DocumentationList, { list: helpItem.documentation })))
        : null;
}
function List(props) {
    const { list } = props;
    if (list === undefined || list.length === 0)
        return null;
    return (React.createElement("ul", { className: "dashed" }, list.map((item, idx) => React.createElement(ListItem, { key: 'helpList_' + idx, item: item }))));
}
function ListItem(props) {
    const { item } = props;
    const label = item.label === undefined
        ? null
        : React.createElement("b", null,
            item.label,
            ": ");
    const link = item.webpage
        ? React.createElement("a", { href: item.webpage, target: "_blank", style: { whiteSpace: 'nowrap' } }, "Read more")
        : null;
    return (React.createElement("li", { style: { marginTop: 5 } },
        label,
        React.createElement("span", null, item.comment ?? item.label),
        " ",
        link));
}
function DocumentationList(props) {
    const { list } = props;
    if (list === undefined || list.length === 0)
        return null;
    return (React.createElement("ul", { className: "dashed" }, list.map((item, idx) => React.createElement(DocItem, { key: 'l' + idx, item: item }))));
}
function DocItem(props) {
    const { item } = props;
    const url = new URL(item.url);
    url.hostname = url.hostname.replace(/^meta/, 'data');
    return (React.createElement("li", { style: { marginTop: 5 } },
        React.createElement("a", { href: url.href, target: '_blank' }, item.txt)));
}
