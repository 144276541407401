import React, { useEffect, useRef } from 'react';
import CheckBtn from '../buttons/CheckBtn';
export default function CheckAllBoxes({ checkCount, totalCount, onChange, disabled }) {
    const areAllChecked = checkCount > 0;
    const checkAllBoxesTitle = checkCount > 0 ? "Select none" : "Select all";
    const checkRef = useRef(null);
    useEffect(() => {
        if (checkRef.current !== null) {
            checkRef.current.checked = areAllChecked;
            checkRef.current.indeterminate = !(checkCount === 0 || checkCount === totalCount);
        }
    });
    return (React.createElement("div", { className: 'py-2 pe-2' },
        React.createElement("label", { style: { margin: -5, padding: 5 } },
            React.createElement(CheckBtn, { checkRef: checkRef, onClick: onChange, isChecked: areAllChecked, checkboxDisabled: disabled, title: checkAllBoxesTitle }))));
}
