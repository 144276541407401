import { SPECCOL } from '../sparqlQueries';
import { distinct, symmetricalDifference } from '../utils';
export const Filter = {
    and: function (fs) {
        return fs.reduce((acc, curr) => {
            if (acc === null)
                return curr;
            if (curr === null)
                return acc;
            const soFar = new Set(acc);
            return curr.filter(v => soFar.has(v));
        }, null);
    },
    allowsNothing: function (f) {
        return f !== null && f.length === 0;
    },
    areEqual(f1, f2) {
        if (f1 === null && f2 === null)
            return true;
        if (f1 === null || f2 === null)
            return false;
        return symmetricalDifference(f1, f2).length === 0;
    }
};
export const Value = {
    isDefined: function (v) {
        return v !== undefined;
    },
    isString: function (v) {
        return v !== undefined && (typeof v) == "string";
    }
};
export default class SpecTable {
    colNames;
    rows;
    filters;
    extraSpecFilter;
    specsCount;
    constructor(colNames, rows, filters, extraSpecFilter = null) {
        this.colNames = colNames;
        this.rows = rows;
        this.filters = filters;
        this.extraSpecFilter = extraSpecFilter;
        this.specsCount = distinct(rows.map(row => row[SPECCOL])).length;
    }
    get serialize() {
        return {
            colNames: this.colNames,
            rows: this.rows,
            filters: this.filters,
            extraSpecFilter: this.extraSpecFilter
        };
    }
    static deserialize(ts) {
        return new SpecTable(ts.colNames, ts.rows, ts.filters ?? {}, ts.extraSpecFilter);
    }
    withExtraSpecFilter(extraFilter) {
        return new SpecTable(this.colNames, this.rows, this.filters, extraFilter);
    }
    get ownSpecFilter() {
        return this.hasOwnFilters ? this.implicitOwnSpecFilter : null;
    }
    get implicitOwnSpecFilter() {
        return this.withExtraSpecFilter(null).getDistinctColValues(SPECCOL);
    }
    withFilter(colName, filter) {
        if (!this.colNames.includes(colName))
            return this;
        const newFilters = Object.assign({}, this.filters, { [colName]: filter });
        return new SpecTable(this.colNames, this.rows, newFilters, this.extraSpecFilter);
    }
    getFilter(colName) {
        return this.filters[colName] ?? null;
    }
    get hasOwnFilters() {
        return Object.values(this.filters).some(f => !!f);
    }
    withResetFilters() {
        return new SpecTable(this.colNames, this.rows, {});
    }
    withFilters(filters) {
        return new SpecTable(this.colNames, this.rows, filters, this.extraSpecFilter);
    }
    getDistinctAvailableColValues(colName) {
        return distinct(this.rowsFilteredByOthers(colName).map(row => row[colName]));
    }
    getDistinctColValues(colName) {
        return distinct(this.filteredRows.map(row => row[colName]));
    }
    rowsFilteredByOthers(excludedColumn) {
        const filters = { ...this.filters, [excludedColumn]: null };
        return this.filterRows(filters);
    }
    get filteredRows() {
        return this.filterRows(this.filters);
    }
    get filteredColumns() {
        return Object.keys(this.filters)
            .filter(col => this.getFilter(col) !== null);
    }
    filterRows(filters) {
        const colNames = Object.keys(filters);
        const eFilter = this.extraSpecFilter;
        return this.rows.filter(row => colNames.every(colName => {
            const filter = filters[colName] ?? null;
            return filter == null || filter.includes(row[colName]);
        }) && (eFilter == null || eFilter.includes(row[SPECCOL])));
    }
    getColumnValuesFilter(colName) {
        return this.hasOwnFilters ? this.getDistinctColValues(colName) : null;
    }
    getAllColValues(colName) {
        return distinct(this.rows.map(row => row[colName]));
    }
}
