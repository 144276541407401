import * as Payloads from "../reducers/actionpayloads";
import { fetchKnownDataObjects, fetchLabelLookup, getExtendedDataObjInfo, getIsBatchDownloadOk, getWhoIam, saveCart } from "../backend";
import { failWithError, fetchCart, getBackendTables } from "./common";
import { saveToRestheart } from "../../../common/main/backend";
import { getLastSegmentInUrl } from "../utils";
export default function bootstrapCart(user) {
    return (dispatch, getState) => {
        const cartHandler = getState().cart.isInitialized
            ? Promise.resolve()
            : dispatch(fetchCart(user));
        cartHandler.then(_ => {
            const state = getState();
            const specTableHandler = (state.specTable.isInitialized || state.cart.items.length === 0)
                ? Promise.resolve()
                : dispatch(getBackendTables([]));
            const labelLookupPromise = Object.keys(state.labelLookup).length
                ? Promise.resolve(undefined)
                : fetchLabelLookup();
            const pids = state.cart.items.map(ci => ci.dobj);
            Promise.all([
                fetchKnownDataObjects(pids.map(id => getLastSegmentInUrl(id))),
                getExtendedDataObjInfo(pids),
                labelLookupPromise,
                specTableHandler //to wait for spec info initialization
            ]).then(([knownDataObjInfos, extendedDataObjInfo, labelLookup]) => {
                dispatch(new Payloads.BootstrapRouteCart(extendedDataObjInfo, knownDataObjInfos.rows, labelLookup));
            }, failWithError(dispatch));
        });
    };
}
export function setCartName(newName) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(updateCart(state.user.email, state.cart.withName(newName)));
    };
}
function updateCart(email, cart) {
    return dispatch => saveCart(email, cart).then(() => dispatch(new Payloads.BackendUpdateCart(cart)));
}
export function logCartDownloadClick(fileName, pids) {
    saveToRestheart({
        cartDownloadClick: {
            fileName,
            pids
        }
    });
}
export const fetchIsBatchDownloadOk = dispatch => {
    Promise.all([getIsBatchDownloadOk(), getWhoIam()])
        .then(([isBatchDownloadOk, user]) => dispatch(new Payloads.BackendBatchDownload(isBatchDownloadOk, user)), failWithError(dispatch));
};
export function updateCheckedObjectsInCart(checkedObjectInCart) {
    return (dispatch) => {
        dispatch(new Payloads.UiUpdateCheckedObjsInCart(checkedObjectInCart));
    };
}
