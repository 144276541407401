import React, { Component } from 'react';
import { SlideIn } from '../ui/SlideIn';
import HelpText from './HelpText';
const closeBtnStyle = {
    float: 'right',
    top: -5,
    fontSize: 26,
    cursor: 'pointer'
};
const panelBodyStyle = {
    width: '100%',
    padding: 10,
    minHeight: 400,
    maxHeight: 600,
    overflowY: 'auto',
    backgroundColor: 'rgba(255, 254, 151, 0.36)'
};
export default class HelpSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helpItem: undefined,
            isOpen: false
        };
    }
    componentWillReceiveProps(nextProps) {
        const helpItem = nextProps.helpStorage.visibleHelpItem;
        if (helpItem)
            this.setState({ helpItem, isOpen: true });
        else
            this.setState({ isOpen: false });
    }
    onCloseBtnClick() {
        this.setState({ isOpen: false });
        this.props.onHelpClose();
    }
    render() {
        const { helpItem, isOpen } = this.state;
        const { width } = this.props;
        return (React.createElement("div", { style: { overflow: 'hidden' } },
            React.createElement(SlideIn, { isOpen: isOpen, width: width },
                React.createElement("div", { className: "card", style: { marginBottom: 0, width } },
                    React.createElement("h5", { className: "card-header" },
                        helpItem?.header,
                        React.createElement("span", { title: "Close", className: "fas fa-times-circle", style: closeBtnStyle, onClick: this.onCloseBtnClick.bind(this) })),
                    React.createElement("div", { className: "card-body", style: panelBodyStyle },
                        React.createElement(HelpText, { helpItem: helpItem }))))));
    }
}
