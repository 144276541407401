import React, { Component } from 'react';
import { connect } from "react-redux";
import { addToCart, removeFromCart } from '../../actions/common';
import { addingToCartProhibition } from '../../models/CartItem';
import { getUrlWithEnvironmentPrefix, specLabelDisplay, stationSpecificTitle } from '../../utils';
import CartBtn from '../buttons/CartBtn';
import DownloadButton from '../buttons/DownloadButton';
class PreviewTitle extends Component {
    render() {
        const { items, extendedDobjInfo, labelLookup, cart } = this.props;
        if (items.length == 0 || items[0].type == undefined)
            return null;
        const specLabel = labelLookup[items[0].spec]?.label ?? "Undefined data type";
        const title = items.length > 1 ? specLabelDisplay(specLabel) : extendedDobjInfo[0].title ?? stationSpecificTitle(extendedDobjInfo[0], items[0].level, specLabel);
        const subtitle = items.length == 1 ? React.createElement("div", { className: "fs-3 text-muted" }, extendedDobjInfo[0].biblioInfo?.temporalCoverageDisplay) : null;
        const metadataButton = items.length == 1 ? getUrlWithEnvironmentPrefix(items[0].dobj) : '';
        const allowCartAdd = items
            .map(addingToCartProhibition)
            .every(cartProhibition => cartProhibition.allowCartAdd);
        const uiMessage = allowCartAdd ? "" : "One or more data objects in this preview cannot be downloaded";
        const areItemsInCart = items.reduce((prevVal, item) => cart.hasItem(item.dobj), false);
        const actionButtonType = areItemsInCart ? 'remove' : 'add';
        const buttonAction = areItemsInCart ? this.handleRemoveFromCart.bind(this) : this.handleAddToCart.bind(this);
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: "col-md-8" }, title),
            React.createElement("div", { className: 'col-auto ms-md-auto d-flex gap-1 py-2' },
                React.createElement(CartBtn, { style: {}, checkedObjects: items.map((item) => item.dobj), clickAction: buttonAction, enabled: allowCartAdd, title: uiMessage, type: actionButtonType }),
                React.createElement(DownloadButton, { style: {}, checkedObjects: items.map((item) => item.dobj), enabled: allowCartAdd })),
            React.createElement("div", { className: "col-md-12" },
                React.createElement("div", { className: 'd-sm-flex justify-content-between align-items-end mb-4 pb-2' },
                    React.createElement("div", null, subtitle)),
                React.createElement("ul", { className: "nav nav-tabs" },
                    React.createElement("li", { className: "nav-item" }, items.length == 1 ?
                        React.createElement("a", { className: "nav-link", href: metadataButton }, "Metadata")
                        :
                            React.createElement("details", { className: "nav-link dropdown-details" },
                                React.createElement("summary", null, "Metadata"),
                                React.createElement("div", { className: 'dropdown', style: { width: 'auto', left: 0, padding: '0.5rem 0' } }, items.map(item => {
                                    return (React.createElement("a", { key: item.dobj, className: 'dropdown-item py-1 px-3', href: getUrlWithEnvironmentPrefix(item.dobj) },
                                        React.createElement("div", null, item.itemName)));
                                })))),
                    React.createElement("li", { className: "nav-item" },
                        React.createElement("a", { className: "nav-link active", "aria-current": "page", href: "#" }, "Preview"))))));
    }
    handleAddToCart(objInfo) {
        this.props.addToCart(objInfo);
    }
    handleRemoveFromCart(objInfo) {
        this.props.removeFromCart(objInfo);
    }
}
function stateToProps(state) {
    return {
        items: state.preview.items,
        extendedDobjInfo: state.extendedDobjInfo,
        labelLookup: state.labelLookup,
        cart: state.cart,
    };
}
function dispatchToProps(dispatch) {
    return {
        addToCart: (ids) => dispatch(addToCart(ids)),
        removeFromCart: (ids) => dispatch(removeFromCart(ids)),
    };
}
export default connect(stateToProps, dispatchToProps)(PreviewTitle);
