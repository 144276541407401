import React from 'react';
import { styles } from '../styles';
export default function PreviewIcon({ preview, style, clickAction }) {
    if (preview.previewType) {
        return (React.createElement("span", { style: style },
            React.createElement("span", { style: styles.clickIcon, title: "Preview data", className: "fas fa-eye", onClick: clickAction })));
    }
    else {
        return (React.createElement("span", { style: style },
            React.createElement("span", { style: styles.disabledClickIcon, title: preview.previewAbsenceReason, className: "fas fa-eye text-muted" })));
    }
}
