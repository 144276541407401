import { numberFilterKeys } from "../config";
export function isPidFilter(filter) {
    return filter.category === "pids";
}
export function isTemporalFilter(filter) {
    return filter.category === "dataTime" || filter.category === "submission";
}
export function isNumberFilter(filter) {
    return numberFilterKeys.includes(filter.category);
}
export function isDeprecatedFilter(filter) {
    return filter.category === "deprecated";
}
export function isVariableFilter(filter) {
    return filter.category === "variableNames";
}
export function isKeywordsFilter(filter) {
    return filter.category === "keywords";
}
export function isGeoFilter(filter) {
    return filter.category === "geo";
}
