import * as Payloads from "../reducers/actionpayloads";
import stateUtils from "../models/State";
import { getProfile, getWhoIam, logOut } from "../backend";
import bootstrapPreview from "./preview";
import bootstrapCart from "./cart";
import bootstrapSearch from "./search";
import { failWithError, loadFromError } from "./common";
export const init = dispatch => {
    const stateFromHash = stateUtils.hashToState();
    getWhoIam().then((user) => {
        if (stateFromHash.error) {
            if (user.email)
                logOut();
            dispatch(loadFromError(user, stateFromHash.error));
        }
        else {
            dispatch(loadApp(user));
        }
    });
};
export function loadApp(user) {
    return (dispatch, getState) => {
        const { route, preview } = getState();
        dispatch(bootstrapRoute(user, route ?? 'search', preview.pids));
        getProfile(user.email).then((resp) => {
            const profile = resp.profile || {};
            dispatch(new Payloads.BackendUserInfo(user, profile));
        });
    };
}
export function bootstrapRoute(user, route, previewPids) {
    return (dispatch, getState) => {
        const { id, tabs } = getState();
        switch (route) {
            case 'search':
                dispatch(bootstrapSearch(user, tabs));
                break;
            case 'preview':
                if (previewPids === undefined || previewPids.length === 0) {
                    failWithError(dispatch)(new Error('Preview cannot be initialized'));
                    break;
                }
                dispatch(bootstrapPreview(user, previewPids));
                break;
            case 'cart':
                dispatch(bootstrapCart(user));
                break;
            case 'metadata':
                if (id)
                    window.location.href = id;
                break;
            default:
                failWithError(dispatch)(new Error(`Argument for route '${route}' is not managed`));
        }
    };
}
