import React, { Component } from 'react';
import CheckBtn from '../buttons/CheckBtn';
import { isSmallDevice, getLastSegmentInUrl, linesToShowStyle, getUrlWithEnvironmentPrefix, stationSpecificTitle } from '../../utils';
import { LinkifyText } from '../LinkifyText';
import config from '../../config';
import { addingToCartProhibition } from '../../models/CartItem';
import CollectionBtn from '../buttons/CollectionBtn';
const truncateStyle = {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};
const iconStation = '//static.icos-cp.eu/images/icons/tower-observation.svg';
const iconArrows = '//static.icos-cp.eu/images/icons/arrows-up-down.svg';
const iconTime = '//static.icos-cp.eu/images/icons/timer.svg';
const iconFile = '//static.icos-cp.eu/images/icons/file.svg';
const iconCalendar = '//static.icos-cp.eu/images/icons/calendar.svg';
const iconLevel = [
    '//static.icos-cp.eu/images/icons/circle-0.svg',
    '//static.icos-cp.eu/images/icons/circle-1.svg',
    '//static.icos-cp.eu/images/icons/circle-2.svg',
    '//static.icos-cp.eu/images/icons/circle-3.svg'
];
export default class SearchResultRegularRow extends Component {
    render() {
        const props = this.props;
        const objInfo = props.objInfo;
        const extendedInfo = props.extendedInfo.theme && props.extendedInfo.themeIcon
            ? props.extendedInfo
            : { ...props.extendedInfo, theme: 'Other data', themeIcon: 'https://static.icos-cp.eu/images/themes/oth.svg' };
        const specLabel = props.labelLookup[objInfo.spec]?.label ?? "Undefined data type";
        const title = extendedInfo.title ?? stationSpecificTitle(extendedInfo, objInfo.level, specLabel);
        const samplingHeight = extendedInfo.samplingHeight ? extendedInfo.samplingHeight + ' meters' : undefined;
        const { allowCartAdd, uiMessage } = addingToCartProhibition(objInfo);
        const checkBtnTitle = uiMessage ?? `Select to preview or download`;
        const level = `Level ${objInfo.level}`;
        return (React.createElement("div", { className: 'd-flex border-bottom py-3' },
            React.createElement("div", { className: 'pe-3' },
                React.createElement("label", { style: { margin: -5, padding: 5 } },
                    React.createElement(CheckBtn, { onClick: () => props.updateCheckedObjects(objInfo.dobj), title: checkBtnTitle, isChecked: props.isChecked, checkboxDisabled: props.isCartView ? false : !allowCartAdd }))),
            React.createElement("div", null,
                React.createElement("h4", { className: "fs-5" },
                    React.createElement("a", { title: "View metadata", href: getUrlWithEnvironmentPrefix(objInfo.dobj) }, title)),
                React.createElement(Description, { extendedInfo: extendedInfo, truncateStyle: truncateStyle }),
                React.createElement("div", { className: "extended-info", style: { marginTop: 4 } },
                    React.createElement(ExtendedInfoItem, { item: extendedInfo.theme, icon: extendedInfo.themeIcon, iconHeight: 14, iconRightMargin: 4, title: "Theme" }),
                    React.createElement(ExtendedInfoItem, { item: level, icon: iconLevel[objInfo.level], iconHeight: 14, title: "Data level" }),
                    React.createElement(CollectionLinks, { dois: extendedInfo.dois }),
                    config.features.displayStationInExtendedInfo && extendedInfo.station &&
                        React.createElement(ExtendedInfoItem, { item: extendedInfo.station.trim(), icon: iconStation, iconHeight: 13, title: "Station" }),
                    config.features.displayFileNameInExtendedInfo && objInfo.fileName &&
                        React.createElement(ExtendedInfoItem, { item: objInfo.fileName, icon: iconFile, iconHeight: 13, title: "File name" }),
                    React.createElement(ExtendedInfoItem, { item: samplingHeight, icon: iconArrows, iconHeight: 14, title: "Sampling height" }),
                    React.createElement(ExtendedInfoItem, { item: objInfo.temporalResolution, icon: iconTime, iconHeight: 13, title: "Time resolution" }),
                    extendedInfo.biblioInfo &&
                        React.createElement(ExtendedInfoItem, { item: extendedInfo.biblioInfo.temporalCoverageDisplay, icon: iconCalendar, iconHeight: 12, iconRightMargin: 2, title: "Temporal coverage" })))));
    }
}
export const getMetadataHash = (dobj) => {
    const hashObj = {
        route: 'metadata',
        id: getLastSegmentInUrl(dobj)
    };
    return "#" + encodeURIComponent(JSON.stringify(hashObj));
};
const Description = ({ extendedInfo, truncateStyle }) => {
    if (!extendedInfo)
        return null;
    if (!extendedInfo.description && !extendedInfo.specComments)
        return null;
    const ownDescription = extendedInfo.description ?? "";
    const separator = ownDescription && extendedInfo.specComments ? ". " : "";
    const description = (extendedInfo.specComments ?? "") + separator + ownDescription;
    return isSmallDevice()
        ? React.createElement("div", { style: truncateStyle, title: description }, description)
        : React.createElement(LinkifyText, { text: description, style: linesToShowStyle(5) });
};
const ExtendedInfoItem = ({ item, icon, iconHeight = 18, iconRightMargin = 0, title = "" }) => {
    const imgStyle = { height: iconHeight, marginRight: iconRightMargin };
    return (item && icon
        ? React.createElement("span", { className: "extended-info-item", title: title },
            React.createElement("img", { src: icon, style: imgStyle }),
            " ",
            React.createElement("span", { style: { verticalAlign: 'middle', overflowWrap: 'anywhere', whiteSpace: 'normal' } }, item))
        : null);
};
const CollectionLinks = ({ dois }) => {
    if (dois === undefined)
        return null;
    return (React.createElement(React.Fragment, null, dois.map((doi, i) => React.createElement("span", { key: i, className: "extended-info-item" },
        React.createElement(CollectionBtn, null),
        React.createElement("a", { href: config.doiBaseUrl + doi, style: { marginLeft: 7 }, target: "_blank" }, doi)))));
};
