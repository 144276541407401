import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AnimatedToasters } from 'icos-cp-toaster';
import Search from './search/Search';
import DataCart from './DataCart';
import Preview from './Preview';
import ErrorBoundary from '../components/ErrorBoundary';
import { updateCheckedObjectsInCart } from '../actions/cart';
import config, { breadcrumbs } from '../config';
import { failWithError, updateRoute } from "../actions/common";
import HelpSection from '../components/help/HelpSection';
import { UiInactivateAllHelp } from '../reducers/actionpayloads';
import PreviewTitle from '../components/preview/PreviewTitle';
export class App extends Component {
    constructor(props) {
        super(props);
    }
    handleRouteClick(newRoute) {
        this.props.updateCheckedObjectsInCart([]);
        this.props.updateRoute(newRoute);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.route !== this.props.route) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        const props = this.props;
        if (!props.route || !props.bootstrapDataArrived) {
            return null;
        }
        return (React.createElement("div", null,
            React.createElement(AnimatedToasters, { toasterData: props.toasterData }),
            React.createElement("div", { className: "row" },
                React.createElement(Breadcrumbs, { handleRouteClick: this.handleRouteClick.bind(this), route: props.route }),
                React.createElement(Title, { route: props.route, metadata: props.metadata })),
            React.createElement(ErrorBoundary, { failWithError: props.failWithError },
                React.createElement(Route, { route: props.route },
                    React.createElement("div", { style: { position: 'absolute', top: -20, right: 15, bottom: 0 } },
                        React.createElement("div", { style: { position: 'sticky', top: 2, padding: 0, zIndex: 9999 } },
                            React.createElement(HelpSection, { width: 300, onHelpClose: this.props.onHelpClose, helpStorage: this.props.helpStorage })))))));
    }
}
const Breadcrumbs = (props) => {
    return (React.createElement("nav", { role: "navigation", "aria-label": "breadcrumb" },
        React.createElement("ol", { className: "breadcrumb bg-light p-2" }, breadcrumbs[config.envri].map(b => BreadcrumbItem(b, props.handleRouteClick, props.route)))));
};
const BreadcrumbItem = (item, handleRouteClick, route) => {
    const onclick = (item.url == "/portal") ? (e) => {
        e.preventDefault();
        handleRouteClick('search');
    } : _ => _;
    return ((item.url == "/portal" && route == 'search') ?
        React.createElement("li", { key: item.label, className: "breadcrumb-item active" }, item.label) :
        React.createElement("li", { key: item.label, className: "breadcrumb-item" },
            React.createElement("a", { onClick: onclick, href: item.url }, item.label)));
};
const Title = (props) => {
    switch (props.route) {
        case 'search':
            return (React.createElement("h1", { className: "col-md-9 mb-3" },
                config.envriName,
                " data portal",
                config.envri === "ICOS" &&
                    React.createElement("span", { className: "fs-3 text-secondary" }, " Search, preview, download data objects")));
        case 'preview':
            return React.createElement(PreviewTitle, null);
        default:
            return React.createElement("div", { className: "col-md-9" });
    }
};
const Route = ({ route, children }) => {
    switch (route) {
        case 'search':
            return (React.createElement(Search, { HelpSection: children }));
        case 'cart':
            return React.createElement(DataCart, null);
        case 'preview':
            return (React.createElement(Preview, { HelpSection: children }));
        case 'metadata':
            return React.createElement(React.Fragment, null);
    }
};
function stateToProps(state) {
    return {
        route: state.route,
        toasterData: state.toasterData,
        cart: state.cart,
        metadata: state.metadata,
        helpStorage: state.helpStorage,
        bootstrapDataArrived: Object.keys(state.labelLookup).length > 0
    };
}
function dispatchToProps(dispatch) {
    return {
        failWithError: (error) => failWithError(dispatch)(error),
        updateRoute: (route) => dispatch(updateRoute(route)),
        updateCheckedObjectsInCart: (ids) => dispatch(updateCheckedObjectsInCart(ids)),
        onHelpClose: () => dispatch(new UiInactivateAllHelp())
    };
}
export default connect(stateToProps, dispatchToProps)(App);
