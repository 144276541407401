import React, { Component } from "react";
import { debounce } from 'icos-cp-utils';
import HelpButton from "../../containers/help/HelpButton";
export default class FilterByFileName extends Component {
    makeQueryDebounced;
    constructor(props) {
        super(props);
        this.makeQueryDebounced = debounce(this.makeQuery.bind(this));
    }
    makeQuery(ev) {
        const fileName = ev.target.value;
        this.props.updateFileName(fileName);
    }
    render() {
        return (React.createElement("div", { className: "row", style: { marginTop: 10 } },
            React.createElement("div", { className: "col-md-12" },
                React.createElement("label", { style: { marginBottom: 0 } }, "Filename (exact match only)"),
                React.createElement(HelpButton, { name: "fileNameFilter" }),
                React.createElement("input", { type: "text", placeholder: "Paste a file name", className: "form-control", onChange: this.makeQueryDebounced, defaultValue: this.props.filterFileName }))));
    }
}
