import React, { Component } from 'react';
export default class CartBtn extends Component {
    constructor(props) {
        super(props);
    }
    handleAddToCartClick() {
        const { checkedObjects, clickAction } = this.props;
        if (clickAction)
            clickAction(checkedObjects);
    }
    render() {
        const { style, enabled, type, title } = this.props;
        const btnText = (type === 'remove') ? 'Remove from cart' : 'Add to cart';
        const btnType = (type === 'remove' || !enabled) ? 'btn-outline-secondary' : 'btn-warning';
        const className = `btn ${btnType} ${enabled ? "" : "disabled"}`;
        const btnStyle = enabled ? {} : { pointerEvents: 'auto', cursor: 'not-allowed' };
        return (React.createElement("div", { style: style },
            React.createElement("button", { id: "add-to-cart-button", onClick: this.handleAddToCartClick.bind(this), className: className, style: btnStyle, disabled: !enabled, title: title }, btnText)));
    }
}
