import React, { Component } from 'react';
export default class EditablePanelHeading extends Component {
    editCtrl;
    constructor(props) {
        super(props);
        this.editCtrl = React.createRef();
        this.state = {
            editMode: false
        };
    }
    handleIconClick() {
        this.setState({ editMode: true });
    }
    handleSaveClick() {
        if (this.editCtrl.current && this.editCtrl.current.value !== this.props.editValue) {
            this.props.saveValueAction(this.editCtrl.current.value);
        }
        this.setState({ editMode: false });
    }
    render() {
        const { editMode } = this.state;
        const { editValue, iconEditClass, iconEditTooltip, iconSaveClass, iconSaveTooltip } = this.props;
        const style = { position: 'absolute', top: 0, left: -20, margin: '0 20px' };
        return (React.createElement(React.Fragment, null, editMode ?
            React.createElement("div", { className: "card-header" },
                React.createElement("span", { className: "card-title" }, editValue),
                React.createElement("form", { className: "input-group", style: style, onSubmit: this.handleSaveClick.bind(this) },
                    React.createElement("input", { ref: this.editCtrl, type: "text", className: "form-control", defaultValue: editValue, autoFocus: true }),
                    React.createElement("button", { className: "btn btn-primary", onClick: this.handleSaveClick.bind(this), title: iconSaveTooltip },
                        React.createElement("span", { className: iconSaveClass }))))
            :
                React.createElement("div", { className: "card-header", onClick: this.handleIconClick.bind(this), style: { cursor: 'pointer' }, title: iconEditTooltip },
                    React.createElement("span", { className: "card-title" }, editValue),
                    React.createElement("span", { className: iconEditClass, style: { float: 'left', margin: '3px 5px' } }))));
    }
}
