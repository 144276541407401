// Modelled after Web Storage API: https://developer.mozilla.org/en-US/docs/Web/API/Storage
import { isInt } from "../types";
export default class Storage {
    state = {};
    get length() {
        const t = this.key(2);
        return Object.keys(this.state).length;
    }
    key(n) {
        return isInt(n) && n >= 0 && n < Object.keys(this.state).length
            ? Object.keys(this.state)[n]
            : null;
    }
    getItem(key) {
        return key in this.state ? this.state[key] : null;
    }
    setItem(key, val) {
        this.state[key] = val;
    }
    removeItem(key) {
        delete this.state[key];
    }
    clear() {
        this.state = {};
    }
}
