import SpecTable, { Filter } from "./SpecTable";
const tableNames = ['basics', 'columnMeta', 'origins'];
export default class CompositeSpecTable {
    basics;
    columnMeta;
    origins;
    id;
    constructor(basics, columnMeta, origins) {
        this.basics = basics;
        this.columnMeta = columnMeta;
        this.origins = origins;
        this.id = Symbol();
    }
    static fromTables(tables) {
        return new CompositeSpecTable(tables[0], tables[1], tables[2]);
    }
    get serialize() {
        return {
            basics: this.basics.serialize,
            columnMeta: this.columnMeta.serialize,
            origins: this.origins.serialize
        };
    }
    static deserialize(tables) {
        const { basics, columnMeta, origins } = tables;
        const basicsTbl = SpecTable.deserialize(basics);
        const columnMetaTbl = SpecTable.deserialize(columnMeta);
        const originsTbl = SpecTable.deserialize(origins);
        return new CompositeSpecTable(basicsTbl, columnMetaTbl, originsTbl).withFilterReflection;
    }
    get tables() {
        return [this.basics, this.columnMeta, this.origins];
    }
    getTable(name) {
        switch (name) {
            case "basics": return this.basics;
            case "columnMeta": return this.columnMeta;
            case "origins": return this.origins;
        }
    }
    getTableRows(name) {
        return this.getTable(name).rows;
    }
    get basicsRows() {
        return this.basics.rows;
    }
    get columnMetaRows() {
        return this.columnMeta.rows;
    }
    get originsRows() {
        return this.origins.rows;
    }
    get isInitialized() {
        return this.basicsRows.length > 0 && this.columnMetaRows.length > 0;
    }
    get names() {
        const toFlatMap = this.tables.map(tbl => tbl.colNames);
        return Array.prototype.concat.apply([], toFlatMap);
    }
    get tableNames() {
        return tableNames.slice();
    }
    findTable(columnName) {
        return this.tables.find(tbl => tbl.colNames.includes(columnName));
    }
    withFilter(colName, filter) {
        const table = this.findTable(colName);
        if (table === undefined)
            return this;
        return CompositeSpecTable.fromTables(this.tables.map(tbl => tbl === table ? table.withFilter(colName, filter) : tbl)).withFilterReflection;
    }
    get withFilterReflection() {
        const self = this;
        const specFilters = [
            this.basics.ownSpecFilter,
            this.columnMeta.ownSpecFilter,
            this.origins.implicitOwnSpecFilter //origins is special, affected by continuous-var filters
        ];
        function specFilterJoin(excludedIdx) {
            const chosenFilts = specFilters.filter((_, idx) => idx != excludedIdx);
            const specFilter0 = Filter.and(chosenFilts);
            return specFilter0 === null
                ? null
                : specFilter0.length < self.basics.specsCount
                    ? specFilter0
                    : null;
        }
        const reflectedTables = this.tables.map((t, idx) => t.withExtraSpecFilter(specFilterJoin(idx)));
        return CompositeSpecTable.fromTables(reflectedTables);
    }
    withResetFilters() {
        return new CompositeSpecTable(this.basics.withResetFilters(), this.columnMeta.withResetFilters(), this.origins.withResetFilters()).withFilterReflection;
    }
    withOriginsTable(origins) {
        const newOrigins = origins.withFilters(this.origins.filters);
        return new CompositeSpecTable(this.basics, this.columnMeta, newOrigins).withFilterReflection;
    }
    getFilter(colName) {
        return this.findTable(colName)?.getFilter(colName) ?? null;
    }
    get hasActiveFilters() {
        return this.tables.some(tbl => tbl.hasOwnFilters);
    }
    getDistinctAvailableColValues(colName) {
        return this.findTable(colName)?.getDistinctAvailableColValues(colName) ?? [];
    }
    getAllDistinctAvailableColValues(colName) {
        const table = this.findTable(colName);
        return table
            ? table.getAllColValues(colName)
            : [];
    }
    getColumnValuesFilter(colName) {
        return this.findTable(colName)?.getColumnValuesFilter(colName) ?? null;
    }
}
