import IndexedDB from "./IndexedDB";
import config from "../config";
const sessionKey = config.portalHistoryStateProps.dbName;
const sessionValue = config.portalHistoryStateProps.storeName;
const signalQuestion = "?";
const signalReply = "!";
export default class PortalHistoryState {
    idb;
    isIdbInitialized = false;
    constructor(props) {
        this.idb = IndexedDB.isIndexedDbAvailable
            ? new IndexedDB(props)
            : undefined;
        if (this.idb !== undefined) {
            this.registerTabSync();
            this.init(this.idb);
        }
    }
    registerTabSync() {
        window.addEventListener('storage', storageTransfer, false);
        if (isSessionStorageEmpty()) {
            signalViaLocalStorage(signalQuestion);
            setTimeout(() => this.cleanUpIndexedDB(), 400);
        }
    }
    async init(idb) {
        const isIdbInitialized = await idb.init();
        this.isIdbInitialized = isIdbInitialized;
    }
    cleanUpIndexedDB() {
        // Check sessionStorage again. It might have been set when other tab answered question.
        if (isSessionStorageEmpty()) {
            this.clearStore();
            // this.prune(12 * 3600 * 1000);
            sessionStorage.setItem(sessionKey, sessionValue);
        }
    }
    async prune(maxAge) {
        if (this.idb === undefined)
            return;
        const keys = await this.idb.getAllKeys() ?? [];
        const now = Date.now();
        keys.forEach(async (key) => {
            const state = await this.idb.getValue(key);
            const ts = state?.ts ?? now;
            if (now - ts > maxAge)
                this.deleteState(key);
        });
    }
    getState() {
        if (this.idb === undefined) {
            return Promise.resolve(history.state);
        }
        else {
            return this.idb.getValue(history.state);
        }
    }
    async pushState(state, url) {
        if (this.idb === undefined) {
            history.pushState(state, '', url);
        }
        else {
            history.pushState(url, '', url);
            return this.idb.addValue({ ...state, url });
        }
    }
    async replaceState(state, url) {
        if (this.idb === undefined) {
            history.replaceState(state, '', url);
        }
        else {
            history.replaceState(url, '', url);
            return this.idb.addValue({ ...state, url });
        }
    }
    deleteState(key) {
        if (this.idb === undefined)
            return;
        this.idb.deleteValue(key);
    }
    clearStore() {
        if (this.idb !== undefined)
            this.idb.clearStore();
    }
}
const isSessionStorageEmpty = () => sessionStorage.getItem(sessionKey) === null;
const signalViaLocalStorage = (message) => {
    localStorage.setItem(sessionKey, message);
    localStorage.removeItem(sessionKey);
};
const storageTransfer = (event) => {
    // Use localStorage to signal between tabs/windows
    const { key, newValue } = event;
    if (!newValue)
        return;
    if (key === sessionKey && newValue === signalQuestion) {
        // Tab/window ask
        signalViaLocalStorage(signalReply);
    }
    if (key === sessionKey && newValue === signalReply) {
        // Tab/window replied
        sessionStorage.setItem(sessionKey, sessionValue);
    }
};
