import CartItem from './CartItem';
export default class Cart {
    _name;
    _items;
    _ts;
    constructor(name = undefined, items = undefined) {
        this._name = name || 'My data cart';
        this._items = items || [];
        this._ts = (items ? Date.now() + '' : '0');
    }
    get serialize() {
        return {
            name: this._name,
            items: this._items.map(item => item.serialize),
            ts: this._ts
        };
    }
    addItem(cartItem) {
        return new Cart(this._name, this._items.concat(cartItem));
    }
    removeItems(ids) {
        return new Cart(this._name, this._items.filter(item => !ids.includes(item.dobj)));
    }
    get ts() {
        return this._ts;
    }
    get isInitialized() {
        return this._ts !== '0';
    }
    get ids() {
        return this._items.map(item => item.dobj);
    }
    get pids() {
        return this._items.map(item => item.dobj.slice(item.dobj.lastIndexOf('/') + 1));
    }
    get count() {
        return this._items.length;
    }
    get items() {
        return this._items;
    }
    item(id) {
        return this._items.find(item => item.dobj === id);
    }
    hasItem(id) {
        return !!this._items.find(item => item.dobj === id);
    }
    get name() {
        return this._name;
    }
    withName(name) {
        return new Cart(name, this._items);
    }
    get size() {
        return this.items.reduce((totSize, item) => totSize + item.size, 0);
    }
}
export const restoreCart = (jsonCart) => {
    const name = jsonCart.cart._name;
    const items = jsonCart.cart._items && jsonCart.cart._items.map((i) => new CartItem(i._id));
    return new Cart(name, items);
};
const emptyJsonCart = new Cart().serialize;
