import React, { Component } from 'react';
import { checkDobjExists } from '../../backend';
import { pidRegexp } from '../../utils';
import HelpButton from '../../containers/help/HelpButton';
export default class FilterByPid extends Component {
    constructor(props) {
        super(props);
        this.state = { message: undefined, validityClass: '' };
    }
    handleSearch(searchStr) {
        if (!searchStr || searchStr == '') {
            this.props.updateSelectedPids(null);
            this.setState({ message: undefined, validityClass: '' });
            return;
        }
        const pidMatch = searchStr.match(pidRegexp);
        if (pidMatch) {
            const self = this;
            this.setState({ validityClass: '' });
            const pidStr = pidMatch[1];
            self.props.updateSelectedPids([pidStr]);
            checkDobjExists(pidStr, this.props.showDeprecated).then(exists => {
                if (exists) {
                    self.setState({ message: undefined, validityClass: ' is-valid' });
                }
                else {
                    self.setState({ message: 'PID not found', validityClass: '' });
                }
            });
        }
        else {
            this.setState({ message: 'Not a valid data object PID', validityClass: ' is-invalid' });
        }
    }
    render() {
        let searchText = '';
        const { filterPids } = this.props;
        if (filterPids && filterPids.length == 1 && this.props.filterFileName == '') {
            searchText = filterPids[0];
        }
        return (React.createElement("div", { className: "row", style: { marginTop: 10 } },
            React.createElement("div", { className: "col-md-12" },
                React.createElement("label", { style: { marginBottom: 0 } }, "PID (exact match only)"),
                React.createElement(HelpButton, { name: "pidFilter" }),
                React.createElement("input", { type: "text", className: "form-control" + this.state.validityClass, title: this.state.message, placeholder: "Paste a PID suffix or complete PID", onChange: e => this.handleSearch(e.target.value), defaultValue: searchText }))));
    }
}
