import React from 'react';
import config, { filters } from "../../config";
import { FilterPanel } from "./FilterPanel";
import { MultiselectCtrl } from "./MultiselectCtrl";
import { debounce } from "icos-cp-utils";
import NumberFilter from "./NumberFilter";
import PickDates from "./PickDates";
import { KeywordFilter } from "./KeywordFilter";
import { isDefined } from "../../utils";
const availableFilters = filters[config.envri];
export const PanelsWithFilters = props => {
    const { specTable, labelLookup, helpStorage, updateFilter, setNumberFilter, filterNumbers, startCollapsed = false, filterTemporal, setFilterTemporal, keywords, filterKeywords, setKeywordFilter, countryCodesLookup } = props;
    return (React.createElement(React.Fragment, null, availableFilters.map((filterPanel, i) => React.createElement(Panel, { key: "i" + i, header: filterPanel.panelTitle, filterList: filterPanel.filterList, filterNumbers: filterNumbers, specTable: specTable, helpStorage: helpStorage, labelLookup: labelLookup, countryCodesLookup: countryCodesLookup, updateFilter: updateFilter, setNumberFilter: setNumberFilter, filterTemporal: filterTemporal, setFilterTemporal: setFilterTemporal, keywords: keywords, filterKeywords: filterKeywords, setKeywordFilter: setKeywordFilter, startCollapsed: startCollapsed }))));
};
const Panel = props => {
    const { header, filterList, specTable, labelLookup, helpStorage, updateFilter, setNumberFilter, filterNumbers, countryCodesLookup, startCollapsed = false, filterTemporal, setFilterTemporal, keywords, filterKeywords, setKeywordFilter } = props;
    if (filterList.length === 0)
        return null;
    return (React.createElement(FilterPanel, { header: header, startCollapsed: startCollapsed }, filterList.map((filterName, i) => React.createElement(FilterCtrl, { key: 'i' + i, filterName: filterName, specTable: specTable, filterNumbers: filterNumbers, helpItem: helpStorage.getHelpItem(filterName), labelLookup: labelLookup, countryCodesLookup: countryCodesLookup, updateFilter: updateFilter, setNumberFilter: setNumberFilter, filterTemporal: filterTemporal, setFilterTemporal: setFilterTemporal, keywords: keywords, filterKeywords: filterKeywords, setKeywordFilter: setKeywordFilter }))));
};
const FilterCtrl = props => {
    const { filterName, specTable, labelLookup, helpItem, updateFilter, setNumberFilter, filterNumbers, filterTemporal, setFilterTemporal, keywords, filterKeywords, setKeywordFilter, countryCodesLookup } = props;
    const filterNumber = filterNumbers.getFilter(filterName);
    if (filterNumber !== undefined) {
        return (React.createElement(NumberFilter, { filterNumber: filterNumber, action: debounce((val) => setNumberFilter(val), 600) }));
    }
    if (filterName === "dataTime" || filterName === "submission") {
        return (React.createElement(React.Fragment, null,
            React.createElement(PickDates, { filterTemporal: filterTemporal, setFilterTemporal: setFilterTemporal, category: filterName })));
    }
    else if (filterName === "keywordFilter") {
        return (React.createElement(KeywordFilter, { keywords: keywords, filterKeywords: filterKeywords, setKeywordFilter: setKeywordFilter }));
    }
    else {
        const { data, value } = getDataValue(filterName, specTable, countryCodesLookup, labelLookup);
        return (React.createElement(MultiselectCtrl, { name: filterName, data: data, value: value, helpItem: helpItem, updateFilter: updateFilter }));
    }
};
function getDataValue(filterName, specTable, countryCodesLookup, labelLookup) {
    const getText = (value) => {
        return filterName === 'countryCode'
            ? countryCodesLookup[value]
            : labelLookup[value]?.label;
    };
    const name = filterName;
    const filterUris = specTable.getFilter(name)?.filter(isDefined) ?? [];
    const dataUris = specTable.getDistinctAvailableColValues(name);
    const data = specTable
        ? makeUniqueDataText(name === 'valType', specTable, dataUris
            .filter(value => isDefined(value) && !filterUris.includes(value))
            .map(value => ({
            value: value,
            text: getText(value) ?? value + '',
            helpStorageListEntry: labelLookup[value]?.list ?? []
        }))).sort((d1, d2) => d1.text.localeCompare(d2.text))
        : [];
    const value = filterUris.map(keyVal => ({
        value: keyVal,
        text: getText(keyVal) ?? keyVal,
        helpStorageListEntry: labelLookup[keyVal]?.list ?? [],
        presentWithCurrentFilters: dataUris.includes(keyVal)
    }));
    return { data, value };
}
;
const makeUniqueDataText = (makeUnique, specTable, data) => {
    if (!makeUnique)
        return data;
    const dataLookup = data.reduce((acc, curr) => {
        acc[curr.text] = (acc[curr.text] ?? 0) + 1;
        return acc;
    }, {});
    return data.map(d => {
        return dataLookup[d.text] === 1
            ? d
            : {
                value: d.value,
                text: `${d.text} [${specTable.columnMetaRows.find(r => r.valType === d.value)?.quantityUnit ?? 'unknown unit'}]`,
                helpStorageListEntry: d.helpStorageListEntry
            };
    });
};
