import React, { Component } from 'react';
export default class Dropdown extends Component {
    outsideClickHandler;
    node = null;
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
        this.outsideClickHandler = this.handleOutsideClick.bind(this);
        document.addEventListener('click', this.outsideClickHandler, false);
    }
    handleOutsideClick(e) {
        if (!this.node)
            return;
        if (e.target === null)
            return;
        if (!this.node.contains(e.target) && this.state.dropdownOpen) {
            this.setState({ dropdownOpen: false });
        }
    }
    onDropdownClick() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }
    onDropDownItemClick(selectedItemKey) {
        if (this.props.itemClickAction) {
            this.setState({ dropdownOpen: !this.state.dropdownOpen });
            this.props.itemClickAction(selectedItemKey);
        }
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClickHandler, false);
    }
    render() {
        const { dropdownOpen } = this.state;
        const { isEnabled, isSorter, selectedItemKey, isAscending, lookup, defaultLbl } = this.props;
        const menuCls = dropdownOpen ? 'dropdown-menu show' : 'dropdown-menu';
        return (React.createElement("span", { ref: span => this.node = span, className: "dropdown", style: { marginLeft: 8 } },
            isSorter
                ? React.createElement(SortButton, { selectedItemKey: selectedItemKey, isAscending: isAscending, clickAction: this.onDropdownClick.bind(this), lookup: lookup, defaultLbl: defaultLbl })
                : React.createElement(Button, { isEnabled: isEnabled, selectedItemKey: selectedItemKey, clickAction: this.onDropdownClick.bind(this), lookup: lookup, defaultLbl: defaultLbl }),
            React.createElement("ul", { className: menuCls }, Object.keys(lookup).map((key, idx) => {
                const glyphCls = key == selectedItemKey ? 'fas fa-sort' : '';
                return (React.createElement("li", { key: 'ddl' + idx, className: "dropdown-item", onClick: this.onDropDownItemClick.bind(this, key), style: { cursor: 'pointer' } },
                    React.createElement("span", { className: glyphCls, style: { display: 'inline-block', width: 14, margin: '5px 5px 0 -5px' } }),
                    " ",
                    lookup[key]));
            }))));
    }
}
const SortButton = ({ selectedItemKey, isAscending, clickAction, lookup, defaultLbl = 'Sort by' }) => {
    const glyphCls = selectedItemKey
        ? isAscending
            ? 'fas fa-sort-amount-down-alt'
            : 'fas fa-sort-amount-down'
        : '';
    const lbl = selectedItemKey
        ? lookup[selectedItemKey]
        : defaultLbl;
    return (React.createElement("button", { className: "btn btn-outline-secondary dropdown-toggle bg-white text-dark", type: "button", onClick: clickAction },
        React.createElement("span", null,
            React.createElement("span", { className: glyphCls }),
            " ",
            lbl),
        " ",
        React.createElement("span", { className: "caret" })));
};
const Button = ({ isEnabled, selectedItemKey, clickAction, lookup, defaultLbl = 'Select option' }) => {
    if (isEnabled) {
        const lbl = selectedItemKey
            ? lookup[selectedItemKey]
            : defaultLbl;
        return (React.createElement("button", { className: "btn btn-outline-secondary dropdown-toggle bg-white text-dark", type: "button", onClick: clickAction },
            React.createElement("span", null, lbl),
            " ",
            React.createElement("span", { className: "caret" })));
    }
    else {
        return (React.createElement("button", { className: "btn btn-outline-secondary dropdown-toggle disabled", type: "button" },
            React.createElement("span", null, defaultLbl),
            " ",
            React.createElement("span", { className: "caret" })));
    }
};
