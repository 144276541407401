import React from "react";
import commonConfig from '../../../common/main/config';
export const sparqlUrl = `${commonConfig.metaBaseUri}/sparqlclient/`;
export const ToSparqlClient = (props) => {
    const postToSparql = () => {
        const form = document.getElementById(formId);
        const input = document.getElementById(queryId);
        input.value = getPublicQuery(queryName);
        form.submit();
    };
    const { comment, label, queryName, getPublicQuery } = props;
    const formId = `form_toSparql_${queryName.replaceAll(" ", "")}`;
    const queryId = `query_toSparql_${queryName.replaceAll(" ", "")}`;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "btn btn-link p-0 text-start", onClick: postToSparql, title: comment },
            React.createElement("i", { className: "fas fa-share-square" }),
            " ",
            label),
        React.createElement("form", { id: formId, method: "POST", action: sparqlUrl, target: "_blank", style: { display: 'none' } },
            React.createElement("input", { type: "hidden", id: queryId, name: "query" }))));
};
