import React, { Component } from 'react';
import Multiselect from 'react-widgets/Multiselect';
import HelpButton from "../../containers/help/HelpButton";
export default class MultiSelectFilter extends Component {
    search;
    itemCount;
    constructor(props) {
        super(props);
        this.search = props.search;
        this.itemCount = undefined;
        this.state = {
            open: false
        };
    }
    handleChange(name, items) {
        this.itemCount = items.length;
        this.props.updateFilter(name, items.map(item => item.value));
        this.setState({ open: false });
    }
    handleToggle(items) {
        const open = this.itemCount === items.length - 1
            ? false
            : !this.state.open;
        this.setState({ open });
    }
    handleSearch(name, value) {
        this.search[name] = value;
    }
    renderListItem(name, shouldUseExternalListEntry, { item }) {
        const { text } = item;
        const searchStr = this.search[name] ? this.search[name].toLowerCase() : '';
        const start = searchStr === ''
            ? -1
            : text.toLowerCase().indexOf(searchStr);
        if (start < 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", null, text),
                this.helpBtn(name, shouldUseExternalListEntry, item)));
        }
        else if (start === 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement("strong", null, text.slice(start, start + searchStr.length)),
                React.createElement("span", null, text.slice(start + searchStr.length)),
                this.helpBtn(name, shouldUseExternalListEntry, item)));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", null, text.slice(0, start)),
                React.createElement("strong", null, text.slice(start, start + searchStr.length)),
                React.createElement("span", null, text.slice(start + searchStr.length)),
                this.helpBtn(name, shouldUseExternalListEntry, item)));
        }
    }
    helpBtn(name, shouldUseExternalListEntry, item) {
        const { text, value, helpStorageListEntry } = item;
        return shouldUseExternalListEntry && helpStorageListEntry.length
            ? React.createElement(HelpButton, { url: value + '', name: name, helpContent: { url: value + '', main: text, helpStorageListEntry } })
            : null;
    }
    renderTagValue(name, shouldUseExternalListEntry, props) {
        const { text, presentWithCurrentFilters } = props.item;
        // Key word filter is not affected by other filters
        return presentWithCurrentFilters || name === "keywordFilter"
            ? React.createElement(React.Fragment, null,
                React.createElement("span", null, text),
                this.helpBtn(name, shouldUseExternalListEntry, props.item))
            : React.createElement(React.Fragment, null,
                React.createElement("span", { style: { color: 'gray' }, title: "Not present with current filters" }, text),
                this.helpBtn(name, shouldUseExternalListEntry, props.item));
    }
    render() {
        const { open } = this.state;
        const { placeholder, data, value, name, shouldUseExternalListEntry } = this.props;
        return (React.createElement(Multiselect, { open: open, placeholder: placeholder, textField: "text", data: data, value: value, filter: "contains", onChange: this.handleChange.bind(this, name), onSearch: this.handleSearch.bind(this, name), onToggle: this.handleToggle.bind(this, value), renderListItem: this.renderListItem.bind(this, name, shouldUseExternalListEntry), renderTagValue: this.renderTagValue.bind(this, name, shouldUseExternalListEntry) }));
    }
}
