import config from '../config';
export default class Paging {
    _objCount;
    _offset;
    _pageCount;
    _limit;
    _filtersEnabled;
    _cacheOffset;
    _isDataEndReached;
    constructor({ objCount, offset, limit, pageCount, filtersEnabled, cacheOffset, isDataEndReached }) {
        this._objCount = objCount;
        this._offset = offset || 0;
        this._pageCount = pageCount ?? config.stepsize;
        this._limit = limit ?? config.stepsize;
        this._filtersEnabled = filtersEnabled || false;
        this._cacheOffset = cacheOffset ?? this._offset;
        this._isDataEndReached = isDataEndReached || false;
    }
    get serialize() {
        return {
            objCount: this._objCount,
            offset: this._offset,
            pageCount: this._pageCount,
            filtersEnabled: this._filtersEnabled,
            cacheOffset: this._cacheOffset,
            isDataEndReached: this._isDataEndReached
        };
    }
    static deserialize(jsonPaging) {
        return new Paging(jsonPaging);
    }
    get objCount() {
        return this._objCount;
    }
    get offset() {
        return this._offset;
    }
    set offset(offset) {
        this._offset = offset;
    }
    get pageCount() {
        return this._pageCount;
    }
    get limit() {
        return this._limit;
    }
    withOffset(offset) {
        return new Paging({
            objCount: this._objCount,
            offset: offset,
            pageCount: this._pageCount,
            filtersEnabled: this._filtersEnabled,
            isDataEndReached: this._isDataEndReached
        });
    }
    withDirection(direction) {
        if (direction < 0) {
            if (this._offset === 0)
                return this;
            const offset = Math.max(0, this._offset - this._limit);
            return new Paging({
                objCount: this._objCount,
                offset,
                cacheOffset: Math.min(offset, this._cacheOffset),
                isDataEndReached: this._isDataEndReached
            });
        }
        else if (direction > 0) {
            if (this._offset + this._limit >= this._objCount)
                return this;
            const offset = this._offset + this._limit;
            return new Paging({
                objCount: this._objCount,
                offset,
                cacheOffset: this._cacheOffset,
                isDataEndReached: this._isDataEndReached
            });
        }
        else
            return this;
    }
    withObjCount({ objCount, pageCount, filtersEnabled, isDataEndReached }) {
        return new Paging({
            objCount,
            offset: this._offset,
            pageCount,
            filtersEnabled,
            cacheOffset: this._cacheOffset,
            isDataEndReached: isDataEndReached
        });
    }
    withFiltersEnabled(filtersEnabled) {
        return new Paging({
            objCount: this._objCount,
            offset: this._offset,
            pageCount: this._pageCount,
            filtersEnabled,
            isDataEndReached: this._isDataEndReached
        });
    }
}
