import React from 'react';
import { batchPreviewAvailability } from '../../models/Preview';
const PreviewBtn = ({ previewLookup, checkedObjects, style, clickAction }) => {
    const handlePreviewClick = () => {
        if (clickAction)
            clickAction(checkedObjects.flatMap(co => co.dobj));
    };
    const preview = batchPreviewAvailability(previewLookup, checkedObjects);
    const disabled = preview.previewType === null;
    const className = "btn btn-outline-secondary " + (disabled ? "disabled" : "");
    const title = disabled ? preview.previewAbsenceReason : "";
    const btnStyle = disabled ? { pointerEvents: 'auto' } : {};
    return (React.createElement("div", { style: style },
        React.createElement("button", { id: "preview-button", onClick: handlePreviewClick, className: className, title: title, style: btnStyle, disabled: disabled }, "Preview")));
};
export default PreviewBtn;
