import React from 'react';
import config from '../../config';
import { sparqlUrl } from '../ToSparqlClient';
const iconStyle = {
    marginLeft: 10,
    cursor: 'pointer'
};
export const FileDownload = ({ getAllFilteredDataObjects, exportQuery, searchResultsCount }) => {
    const { isFetchingCVS, sparqClientQuery } = exportQuery;
    const downloadCount = searchResultsCount > config.exportCSVLimit ? `first ${config.exportCSVLimit.toLocaleString()}` : searchResultsCount.toLocaleString();
    const saveTitle = `Export ${downloadCount} records to CSV`;
    const openSparqlQuery = () => {
        const form = document.getElementById("sparqlClientForm");
        form.submit();
    };
    if (isFetchingCVS)
        return (React.createElement("span", null,
            React.createElement("span", { style: { ...iconStyle, ...{ color: 'gray' } }, className: "fas fa-download", title: "Fetching CSV..." }),
            React.createElement("span", { style: iconStyle, onClick: openSparqlQuery, className: "fas fa-share-square", title: "Open SPARQL query" })));
    else
        return (React.createElement("span", null,
            React.createElement("span", { style: iconStyle, onClick: getAllFilteredDataObjects, className: "fas fa-download", title: saveTitle }),
            React.createElement("span", { style: iconStyle, onClick: openSparqlQuery, className: "fas fa-share-square", title: "Open SPARQL query for basic search result. See Advanced tab for more queries." }),
            React.createElement("form", { id: "sparqlClientForm", method: "POST", action: sparqlUrl, target: "_blank", style: { display: 'none' } },
                React.createElement("input", { type: "hidden", name: "query", value: sparqClientQuery }))));
};
