import React, { Component } from 'react';
import { connect } from 'react-redux';
import { failWithError } from '../../actions/common';
import { Value } from '../../models/SpecTable';
import { Copyright } from 'icos-cp-copyright';
class SearchResultMap extends Component {
    initMap = undefined;
    constructor(props) {
        super(props);
    }
    componentDidUpdate() {
        if (this.initMap === undefined)
            return;
        this.initMap.incomingPropsUpdated({
            allStations: this.props.allStations,
            mapProps: this.props.mapProps,
            selectedStations: this.props.selectedStations
        });
    }
    render() {
        return (React.createElement("div", { id: "map", style: { width: '100%', height: '90vh', position: 'relative' }, tabIndex: 1 },
            React.createElement("div", { id: "stationFilterCtrl", className: "ol-control ol-layer-control-ur", style: { top: 70, fontSize: 20 } }),
            React.createElement("div", { id: "popover", className: "ol-popup" }),
            React.createElement("div", { id: "projSwitchCtrl", className: "ol-layer-control ol-layer-control-lr", style: { zIndex: 99, marginRight: 10, padding: 0 } }),
            React.createElement("div", { id: "layerCtrl", className: "ol-layer-control ol-layer-control-ur" }),
            React.createElement("div", { id: "attribution", className: "ol-attribution ol-unselectable ol-control ol-uncollapsible", style: { right: 15 } },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Copyright, null))),
                React.createElement("ul", null,
                    React.createElement("li", { id: "baseMapAttribution" })))));
    }
    componentDidMount() {
        (async () => {
            const { default: InitMap } = await import(
            /* webpackMode: "lazy" */
            /* webpackChunkName: "init-map" */
            '../../models/InitMap');
            this.initMap = new InitMap({
                mapRootElement: document.getElementById('map'),
                allStations: this.props.allStations,
                stationPos4326Lookup: this.props.stationPos4326Lookup,
                persistedMapProps: this.props.persistedMapProps,
                mapProps: this.props.mapProps,
                updateMapSelectedSRID: this.props.updateMapSelectedSRID,
                updatePersistedMapProps: this.props.updatePersistedMapProps,
                labelLookup: this.props.labelLookup,
                selectedStations: this.props.selectedStations
            });
        })()
            .catch(error => {
            this.props.failWithError(error);
        });
    }
    componentWillUnmount() {
        if (this.initMap) {
            this.initMap.olWrapper.destroyMap();
            this.initMap = undefined;
        }
    }
}
function stateToProps(state) {
    return {
        specTable: state.specTable,
        allStations: state.baseDobjStats.getAllColValues("station").filter(Value.isString),
        stationPos4326Lookup: state.stationPos4326Lookup,
        labelLookup: state.labelLookup,
        selectedStations: state.specTable.origins.getDistinctColValues("station").filter(Value.isString),
        mapProps: state.mapProps
    };
}
function dispatchToProps(dispatch) {
    return {
        failWithError: (error) => failWithError(dispatch)(error),
    };
}
export default connect(stateToProps, dispatchToProps)(SearchResultMap);
