import config from '../config';
import { previewVarCompare } from './Preview';
export default class PreviewLookup {
    table;
    varInfo;
    constructor(table, varInfo) {
        this.table = table;
        this.varInfo = varInfo;
    }
    static init(specTable, labelLookup) {
        return new PreviewLookup(getTable(specTable, labelLookup), {});
    }
    withVarInfo(varInfo) {
        return new PreviewLookup(this.table, varInfo);
    }
    forDataObjSpec(spec) {
        return this.table[spec];
    }
    hasVarInfo(dobj) {
        return this.varInfo[dobj];
    }
}
function getTable(specTable, labelLookup) {
    const specFormats = {};
    const specToCols = {};
    const table = {};
    specTable.basics.rows.forEach(({ spec, format }) => {
        if (typeof spec === 'string' && typeof format === 'string') {
            specFormats[spec] = format;
        }
    });
    specTable.columnMeta.rows.forEach(({ spec, varTitle, valType }) => {
        if (typeof spec === 'string' && typeof varTitle === 'string' && typeof valType === 'string') {
            const oldCols = specToCols[spec];
            const cols = oldCols ?? [];
            cols.push({ varTitle, valType });
            if (!oldCols)
                specToCols[spec] = cols;
        }
    });
    function latLonPresent(cols) {
        return cols.some(col => col.valType === config.mapGraph.latValueType)
            && cols.some(col => col.valType === config.mapGraph.lonValueType);
    }
    Object.entries(specFormats).forEach(([spec, format]) => {
        const cols = specToCols[spec] ?? [];
        if (format === config.netCdf.format)
            table[spec] = { type: "NETCDF" };
        else if (config.imageMultiZipFormats.includes(format))
            table[spec] = { type: "PHENOCAM" };
        else if (config.mapGraph.formats.includes(format) && latLonPresent(cols))
            table[spec] = { type: "MAPGRAPH" };
        else if (cols.length > 0) {
            const options = cols.map(({ varTitle, valType }) => {
                const valTypeLabel = labelLookup[valType].label;
                return { varTitle, valTypeLabel };
            });
            options.sort(previewVarCompare);
            table[spec] = { type: "TIMESERIES", options };
        }
    });
    return table;
}
