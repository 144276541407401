import { BootstrapInfo, BackendUserInfo, BackendObjectMetadataId, BackendObjectMetadata, BackendOriginsTable, BackendUpdateSpecFilter, BackendObjectsFetched, BackendExtendedDataObjInfo, BackendTsSettings, BackendBatchDownload, BackendUpdateCart, BackendExportQuery } from "./actionpayloads";
import stateUtils from "../models/State";
import config from "../config";
import CompositeSpecTable from "../models/CompositeSpecTable";
import Paging from "../models/Paging";
import PreviewLookup from "../models/PreviewLookup";
import { getObjCount, isInPidFilteringMode } from "./utils";
export default function (state, payload) {
    if (payload instanceof BackendUserInfo) {
        return stateUtils.update(state, handleUserInfo(state, payload));
    }
    if (payload instanceof BackendBatchDownload) {
        return stateUtils.update(state, handleBatchDownload(state, payload));
    }
    if (payload instanceof BootstrapInfo) {
        return stateUtils.update(state, bootstrapInfoUpdates(state, payload));
    }
    if (payload instanceof BackendOriginsTable) {
        return stateUtils.updateAndSave(state, handleOriginsTable(state, payload));
    }
    if (payload instanceof BackendUpdateSpecFilter) {
        return stateUtils.update(state, handleSpecFilterUpdate(state, payload));
    }
    if (payload instanceof BackendObjectMetadataId) {
        return stateUtils.update(state, { id: payload.id });
    }
    if (payload instanceof BackendObjectMetadata) {
        return stateUtils.update(state, {
            route: 'metadata',
            metadata: payload.metadata
        });
    }
    if (payload instanceof BackendObjectsFetched) {
        return stateUtils.update(state, handleObjectsFetched(state, payload));
    }
    if (payload instanceof BackendExportQuery) {
        return stateUtils.update(state, {
            exportQuery: payload
        });
    }
    if (payload instanceof BackendExtendedDataObjInfo) {
        // Save updates in history state if we are beyond init procedure since this is called last for the search route
        const updater = state.isRunningInit
            ? stateUtils.update
            : stateUtils.updateAndSave;
        return updater(state, handleExtendedDataObjInfo(state, payload));
    }
    if (payload instanceof BackendTsSettings) {
        return stateUtils.update(state, {
            tsSettings: payload.tsSettings
        });
    }
    if (payload instanceof BackendUpdateCart) {
        return stateUtils.update(state, {
            cart: payload.cart,
            checkedObjectsInCart: state.checkedObjectsInCart.filter(uri => payload.cart.ids.includes(uri))
        });
    }
    return state;
}
;
const handleExtendedDataObjInfo = (state, payload) => {
    const varInfo = payload.extendedDobjInfo
        .reduce((acc, ext) => {
        acc[ext.dobj] = ext.hasVarInfo ?? false;
        return acc;
    }, {});
    return {
        extendedDobjInfo: payload.extendedDobjInfo,
        previewLookup: state.previewLookup?.withVarInfo(varInfo)
    };
};
const handleObjectsFetched = (state, payload) => {
    const objectsTable = payload.objectsTable;
    const extendedObjectsTable = objectsTable.map(ot => {
        const spec = state.specTable.getTableRows('basics').find(r => r.spec === ot.spec);
        return { ...ot, ...spec };
    });
    const paging = state.paging.withObjCount({
        objCount: getObjCount(state.specTable),
        pageCount: payload.objectsTable.length,
        filtersEnabled: isInPidFilteringMode(state.tabs, state.filterPids),
        isDataEndReached: payload.isDataEndReached
    });
    return {
        objectsTable: extendedObjectsTable,
        paging
    };
};
const handleSpecFilterUpdate = (state, payload) => {
    const specTable = state.specTable.withFilter(payload.varName, payload.filter);
    return stateUtils.update(state, {
        specTable,
        objectsTable: [],
        ...getNewPaging(state.paging, state.page, specTable, true),
        filterCategories: Object.assign(state.filterCategories, { [payload.varName]: payload.filter }),
        checkedObjectsInSearch: []
    });
};
const handleOriginsTable = (state, payload) => {
    const specTable = state.specTable.withOriginsTable(payload.table);
    if (isInPidFilteringMode(state.tabs, state.filterPids))
        return { specTable };
    let baseDobjStats = state.baseDobjStats;
    if (!state.mapProps.rects || state.mapProps.rects.length == 0)
        baseDobjStats = payload.table;
    return {
        specTable,
        ...getNewPaging(state.paging, state.page, specTable, payload.resetPaging),
        baseDobjStats
    };
};
export const getNewPaging = (currentPaging, currentPage, specTable, resetPaging) => {
    const objCount = getObjCount(specTable);
    if (resetPaging) {
        return {
            paging: new Paging({ objCount }),
            page: 0
        };
    }
    else {
        const pageCount = Math.min(objCount, config.stepsize);
        return {
            paging: currentPaging.withObjCount({ objCount, pageCount }),
            page: currentPage
        };
    }
};
function bootstrapInfoUpdates(state, payload) {
    const startTable = CompositeSpecTable.deserialize(payload.info.specTables);
    const specTable = applyFilterCategories(startTable, state.filterCategories);
    const labelLookup = payload.info.labelLookup;
    return {
        specTable,
        baseDobjStats: specTable.origins,
        labelLookup,
        ...getNewPaging(state.paging, state.page, specTable, false),
        previewLookup: PreviewLookup.init(specTable, labelLookup),
        keywords: payload.info.keywords,
        countryCodesLookup: payload.info.countryCodes,
        stationPos4326Lookup: payload.info.stationPos4326Lookup
    };
}
function applyFilterCategories(startTable, filterCategories) {
    const categoryTypes = Object.keys(filterCategories);
    return categoryTypes.reduce((specTable, categType) => {
        const filter = filterCategories[categType];
        return filter === undefined ? specTable : specTable.withFilter(categType, filter);
    }, startTable);
}
const handleUserInfo = (state, payload) => {
    return {
        user: {
            profile: payload.profile,
            email: payload.user.email
        }
    };
};
const handleBatchDownload = (state, payload) => {
    return {
        user: Object.assign({}, state.user, payload.user),
        batchDownloadStatus: {
            isAllowed: payload.isBatchDownloadOk,
            ts: Date.now()
        }
    };
};
