import React, { Component } from 'react';
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }
    componentDidCatch(error) {
        this.setState({ hasError: true });
        if (this.props.failWithError)
            this.props.failWithError(error);
    }
    render() {
        if (this.state.hasError) {
            return React.createElement("h4", null, "An error occurred");
        }
        return React.createElement(React.Fragment, null, this.props.children);
    }
}
