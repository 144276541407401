import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
export default class PickDates extends Component {
    onDateSet(sender, date) {
        const selectedDate = date == null ? undefined : date;
        const { category, filterTemporal, setFilterTemporal } = this.props;
        let newFilter = undefined;
        if (sender === 'from') {
            newFilter = category === 'dataTime'
                ? filterTemporal.withDataTimeFrom(selectedDate)
                : filterTemporal.withSubmissionFrom(selectedDate);
        }
        else if (sender === 'to') {
            newFilter = category === 'dataTime'
                ? filterTemporal.withDataTimeTo(selectedDate)
                : filterTemporal.withSubmissionTo(selectedDate);
        }
        else {
            throw new Error('Unknown sender category: ' + sender);
        }
        if (newFilter)
            setFilterTemporal(newFilter);
    }
    render() {
        const { category, filterTemporal } = this.props;
        const from = filterTemporal[category].from;
        const to = filterTemporal[category].to;
        const maxDate = filterTemporal[category].maxDate;
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-md-12" },
                React.createElement("div", { className: "row mt-3" },
                    React.createElement("div", { className: "col-6" },
                        React.createElement(DatePicker, { className: "form-control", selected: from, onChange: (date) => this.onDateSet('from', Array.isArray(date) ? date[0] : date), selectsStart: true, startDate: from, endDate: to, maxDate: to ?? maxDate, calendarStartDay: 1, dateFormat: "yyyy-MM-dd", isClearable: true, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select", dateFormatCalendar: " ", placeholderText: "From" })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement(DatePicker, { className: "form-control", selected: to, onChange: (date) => this.onDateSet('to', Array.isArray(date) ? date[1] : date), selectsEnd: true, startDate: from, endDate: to, minDate: from, maxDate: maxDate, calendarStartDay: 1, dateFormat: "yyyy-MM-dd", isClearable: true, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select", dateFormatCalendar: " ", placeholderText: "To" }))))));
    }
}
