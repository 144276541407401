import LinearCache from "./models/LinearCache";
import { fetchFilteredDataObjects } from "./backend";
import deepEqual from 'deep-equal';
import config from './config';
export class CachedDataObjectsFetcher {
    fetchLimit;
    cacheId;
    linearCache;
    constructor(fetchLimit) {
        this.fetchLimit = fetchLimit;
        this.fetchLimit = fetchLimit;
        this.cacheId = undefined;
        this.linearCache = undefined;
    }
    fetch(options) {
        const cacheId = Object.assign({}, options, { paging: null });
        const { offset, limit } = options.paging;
        if (!deepEqual(this.cacheId, cacheId)) {
            this.cacheId = cacheId;
            this.linearCache = new LinearCache((offset, limit) => {
                const opts = Object.assign({}, options, { paging: { offset, limit } });
                return fetchFilteredDataObjects(opts).then((res) => res.rows);
            }, this.fetchLimit, offset);
        }
        return this.linearCache.fetch(offset, limit).then((rows) => ({
            rows,
            cacheSize: this.linearCache.length,
            isDataEndReached: this.linearCache.isDataEndReached
        }));
    }
}
export class DataObjectsFetcher {
    fetch(options) {
        return fetchFilteredDataObjects(options).then(({ rows }) => {
            return {
                rows,
                cacheSize: 0,
                isDataEndReached: rows.length < config.stepsize
            };
        });
    }
}
