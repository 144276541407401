import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllFilteredDataObjects, requestStep, toggleSort } from "../../actions/search";
import { addToCart, removeFromCart } from "../../actions/common";
import config, { timezone } from "../../config";
import { Paging } from "../../components/buttons/Paging";
import SearchResultCompactRow from "../../components/searchResult/SearchResultCompactRow";
const headerStyle = { whiteSpace: 'nowrap', paddingRight: 0, paddingBottom: 0 };
class SearchResultCompact extends Component {
    render() {
        const { preview, cart, objectsTable, addToCart, lookup, paging, sorting, searchOptions, toggleSort, requestStep, removeFromCart, handlePreview, getAllFilteredDataObjects, exportQuery, extendedDobjInfo, user } = this.props;
        const sortProps = { sorting, toggleSort };
        return (React.createElement("div", { className: "card" },
            React.createElement(Paging, { searchOptions: searchOptions, type: "header", paging: paging, requestStep: requestStep, getAllFilteredDataObjects: getAllFilteredDataObjects, exportQuery: exportQuery }),
            React.createElement("div", { className: "card-body" },
                React.createElement("div", { className: "table-responsive" },
                    React.createElement("table", { className: "table" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { style: headerStyle },
                                    "Data object",
                                    React.createElement(SortButton, { varName: "fileName", ...sortProps })),
                                React.createElement("th", { style: headerStyle },
                                    "Size",
                                    React.createElement(SortButton, { varName: "size", ...sortProps })),
                                React.createElement("th", { style: headerStyle },
                                    "Submission time (",
                                    timezone[config.envri].label,
                                    ")",
                                    React.createElement(SortButton, { varName: "submTime", ...sortProps })),
                                React.createElement("th", { style: headerStyle },
                                    "From time (",
                                    timezone[config.envri].label,
                                    ")",
                                    React.createElement(SortButton, { varName: "timeStart", ...sortProps })),
                                React.createElement("th", { style: headerStyle },
                                    "To time (",
                                    timezone[config.envri].label,
                                    ")",
                                    React.createElement(SortButton, { varName: "timeEnd", ...sortProps })))),
                        React.createElement("tbody", null, objectsTable.map((objInfo, i) => {
                            const isAddedToCart = cart.hasItem(objInfo.dobj);
                            return (React.createElement(SearchResultCompactRow, { lookup: lookup, preview: preview, extendedDobjInfo: extendedDobjInfo, handlePreview: handlePreview, objInfo: objInfo, isAddedToCart: isAddedToCart, addToCart: addToCart, removeFromCart: removeFromCart, key: 'dobj_' + i, user: user }));
                        })))))));
    }
}
const SortButton = props => {
    const sorting = props.sorting || {};
    const glyphClass = 'fas fa-sort' + (sorting.varName !== props.varName
        ? ''
        : sorting.ascending
            ? '-amount-down-alt'
            : '-amount-down');
    const style = { verticalAlign: 'baseline', padding: 6 };
    const sortHandler = props.toggleSort ? props.toggleSort.bind(null, props.varName) : undefined;
    return (React.createElement("button", { className: "btn", title: "Sort", onClick: sortHandler, style: style },
        React.createElement("span", { className: glyphClass })));
};
function stateToProps(state) {
    return {
        lookup: state.previewLookup,
        objectsTable: state.objectsTable,
        preview: state.preview,
        cart: state.cart,
        paging: state.paging,
        sorting: state.sorting,
        searchOptions: state.searchOptions,
        exportQuery: state.exportQuery,
        extendedDobjInfo: state.extendedDobjInfo,
        user: state.user
    };
}
function dispatchToProps(dispatch) {
    return {
        addToCart: (ids) => dispatch(addToCart(ids)),
        toggleSort: (varName) => dispatch(toggleSort(varName)),
        requestStep: (direction) => dispatch(requestStep(direction)),
        removeFromCart: (ids) => dispatch(removeFromCart(ids)),
        getAllFilteredDataObjects: () => dispatch(getAllFilteredDataObjects()),
    };
}
export default connect(stateToProps, dispatchToProps)(SearchResultCompact);
