import Preview from "./Preview";
import FilterTemporal from "./FilterTemporal";
import CompositeSpecTable from "./CompositeSpecTable";
import PreviewLookup from "./PreviewLookup";
import Cart from "./Cart";
import Paging from "./Paging";
import HelpStorage from './HelpStorage';
import config, { prefixes, numberFilterKeys } from "../config";
import deepequal from 'deep-equal';
import SpecTable from "./SpecTable";
import { getLastSegmentInUrl, pick } from "../utils";
import { FilterNumber, FilterNumbers } from "./FilterNumbers";
import PortalHistoryState from "../backend/PortalHistoryState";
export const portalHistoryState = new PortalHistoryState(config.portalHistoryStateProps);
// hashKeys objects are automatically represented in the URL hash (with some special cases).
// Changes to any of these objects are automatically saved in browser history state
// If other objects needs to be saved in browser history, use method 'updateAndSave'
const hashKeys = [
    'route',
    'filterCategories',
    'filterTemporal',
    'filterPids',
    'filterNumbers',
    'filterKeywords',
    'tabs',
    'page',
    'id',
    'preview',
    'searchOptions',
    'mapProps',
    'yAxis',
    'y2Axis',
    'itemsToAddToCart'
];
export const emptyCompositeSpecTable = new CompositeSpecTable(new SpecTable([], [], {}), new SpecTable([], [], {}), new SpecTable([], [], {}));
export const defaultState = {
    ts: Date.now(),
    isRunningInit: true,
    searchOptions: {
        showDeprecated: false
    },
    route: undefined,
    countryCodesLookup: {},
    filterCategories: {},
    filterTemporal: new FilterTemporal(),
    filterPids: null,
    filterNumbers: new FilterNumbers(numberFilterKeys.map(cat => new FilterNumber(cat))),
    filterFileName: "",
    user: {
        profile: {},
        email: null
    },
    previewLookup: undefined,
    labelLookup: {},
    stationPos4326Lookup: {},
    specTable: emptyCompositeSpecTable,
    baseDobjStats: new SpecTable([], [], {}),
    mapProps: {
        srid: config.olMapSettings.defaultSRID,
        rects: []
    },
    extendedDobjInfo: [],
    formatToRdfGraph: {},
    objectsTable: [],
    sorting: {
        varName: "submTime",
        ascending: false
    },
    paging: new Paging({ objCount: 0 }),
    cart: new Cart(),
    id: undefined,
    metadata: undefined,
    station: undefined,
    preview: new Preview(),
    yAxis: undefined,
    y2Axis: undefined,
    itemsToAddToCart: undefined,
    toasterData: undefined,
    batchDownloadStatus: {
        isAllowed: false,
        ts: 0
    },
    checkedObjectsInSearch: [],
    checkedObjectsInCart: [],
    tabs: {},
    page: 0,
    tsSettings: {},
    helpStorage: new HelpStorage(),
    keywords: { specLookup: {}, dobjKeywords: [] },
    filterKeywords: [],
    exportQuery: {
        isFetchingCVS: false,
        sparqClientQuery: ''
    }
};
const update = (state, updates) => {
    return Object.assign({}, state, updates, { ts: Date.now() });
};
// history state is only automatically updated when URL changes. Use this method to force
// history to store current state.
const updateAndSave = (state, updates) => {
    const newState = update(state, updates);
    portalHistoryState.replaceState(serialize(newState), window.location.href).then(_ => _, reason => console.error(`Failed to add value to indexed database because ${reason}`));
    return newState;
};
const serialize = (state) => {
    return { ...state,
        filterTemporal: state.filterTemporal.serialize,
        filterNumbers: state.filterNumbers.serialize,
        specTable: state.specTable.serialize,
        baseDobjStats: state.baseDobjStats.serialize,
        paging: state.paging.serialize,
        cart: undefined,
        preview: state.preview.serialize,
        helpStorage: state.helpStorage.serialize
    };
};
const deserialize = (jsonObj, cart) => {
    const specTable = CompositeSpecTable.deserialize(jsonObj.specTable);
    const { table, varInfo } = jsonObj.previewLookup ?? {};
    const previewLookup = table && varInfo
        ? new PreviewLookup(table, varInfo)
        : PreviewLookup.init(specTable, jsonObj.labelLookup);
    const baseDobjStats = SpecTable.deserialize(jsonObj.baseDobjStats);
    const props = { ...jsonObj,
        filterTemporal: FilterTemporal.deserialize(jsonObj.filterTemporal),
        filterNumbers: FilterNumbers.deserialize(jsonObj.filterNumbers),
        previewLookup,
        specTable,
        baseDobjStats,
        paging: Paging.deserialize(jsonObj.paging),
        cart,
        preview: Preview.deserialize(jsonObj.preview),
        helpStorage: HelpStorage.deserialize(jsonObj.helpStorage),
        exportQuery: { isFetchingCVS: false, sparqClientQuery: jsonObj.exportQuery.sparqClientQuery } };
    return props;
};
// Hash to state and state to hash below
const getStateFromHash = () => {
    const state = jsonToState(parseHash(getCurrentHash()));
    return extendUrls(state);
};
//No # in the beginning!
const parseHash = (hash) => {
    try {
        return JSON.parse(hash);
    }
    catch (err) {
        return {};
    }
};
const hashToState = () => {
    try {
        return JSON.parse(getCurrentHash());
    }
    catch (err) {
        return {};
    }
};
const getStateFromStore = (storeState) => {
    return hashKeys.reduce((acc, key) => {
        acc[key] = storeState[key];
        return acc;
    }, {});
};
const simplifyState = (state) => {
    return Object.assign(state, {
        filterTemporal: state.filterTemporal ? state.filterTemporal.serialize : {},
        filterNumbers: state.filterNumbers.serialize,
        preview: state.preview.pids
    });
};
const jsonToState = (state0) => {
    const state = { ...defaultState, ...state0 };
    try {
        if (state0.filterTemporal) {
            state.filterTemporal = new FilterTemporal().restore(state0.filterTemporal);
        }
        if (state0.filterNumbers) {
            state.filterNumbers = defaultState.filterNumbers.restore(state0.filterNumbers);
        }
        state.preview = new Preview().withPids(state0.preview ?? []);
        state.preview.yAxis = state0.yAxis;
        state.preview.y2Axis = state0.y2Axis;
        if (state0.id) {
            state.id = config.objectUriPrefix[config.envri] + state0.id;
        }
    }
    catch (err) {
        console.error({ state, state0, err });
        throw new Error("Could not convert json to state");
    }
    return state;
};
const handleRoute = (storeState) => {
    if (storeState.route === 'search') {
        delete storeState.route;
    }
};
const specialCases = (state) => {
    if (state.route === 'preview') {
        return {
            route: state.route,
            preview: state.preview,
            yAxis: state.yAxis,
            y2Axis: state.y2Axis,
            itemsToAddToCart: state.itemsToAddToCart
        };
    }
    else {
        delete state.preview;
    }
    if (state.route === 'cart') {
        return {
            route: state.route
        };
    }
    if (state.route === 'search') {
        delete state.route;
    }
    return state;
};
function getCurrentHash() {
    return decodeURIComponent(window.location.hash.substring(1));
}
const hashUpdater = (store) => () => {
    const state = store.getState();
    const newHash = stateToHash(state);
    const oldHash = getCurrentHash();
    if (newHash !== oldHash) {
        if (newHash === '') {
            portalHistoryState.pushState(serialize(state), window.location.href.split('#')[0]).then(_ => _, reason => console.log(`Failed to add value to indexed database because ${reason}`));
        }
        else {
            window.location.hash = encodeURIComponent(newHash);
        }
    }
};
const storeOverwatch = (store, stateKeys, onChange) => {
    let currentState;
    const handleChange = () => {
        const nextState = pick(store.getState(), ...stateKeys);
        if (currentState === undefined)
            currentState = nextState;
        const changes = stateKeys.reduce((acc, key) => {
            if (!deepequal(currentState[key], nextState[key]))
                acc[key] = nextState[key];
            return acc;
        }, {});
        if (Object.keys(changes).length) {
            onChange(currentState, nextState, changes, (state) => pick(state, ...stateKeys));
            currentState = nextState;
        }
    };
    handleChange();
    // Return unsubscriber
    return store.subscribe(handleChange);
};
const stateToHash = (state) => {
    const currentStoreState = getStateFromStore(state);
    const simplifiedStoreState = simplifyState(currentStoreState);
    const reducedStoreState = reduceState(simplifiedStoreState);
    handleRoute(reducedStoreState);
    const withSpecialCases = specialCases(reducedStoreState);
    const final = shortenUrls(withSpecialCases);
    return Object.keys(final).length ? JSON.stringify(final) : '';
};
const shortenUrls = (state = {}) => {
    return managePrefixes(state, (prefix, value) => {
        if (Array.isArray(prefix)) {
            // TODO: remove prefix handling since wdcgg is no longer present
            const prefixObj = prefix.find(p => value.startsWith(p.value) || p.prefix === 'i');
            if (prefixObj === undefined)
                throw new Error(`Could not find prefix for ${value}`);
            return prefixObj.prefix + value.slice(prefixObj.value.length);
        }
        else {
            return value.slice(prefix.length);
        }
    });
};
const extendUrls = (state) => {
    return managePrefixes(state, (prefix, value) => {
        if (value.startsWith('http://') || value.startsWith('https://'))
            return value;
        if (Array.isArray(prefix)) {
            const pLetter = value.slice(0, 1);
            const prefixObj = prefix.find(p => p.prefix === pLetter);
            if (prefixObj === undefined)
                throw new Error(`Could not find prefix for ${value}`);
            return prefixObj.value + value.slice(1);
        }
        else {
            return prefix + value;
        }
    });
};
const managePrefixes = (state = {}, transform) => {
    if (Object.keys(state).length === 0)
        return state;
    if (state.filterCategories === undefined || Object.keys(state.filterCategories).length === 0)
        return state;
    const categories = Object.keys(state.filterCategories);
    const appPrefixes = prefixes[config.envri];
    const fc = state.filterCategories;
    return {
        ...state, ...{
            filterCategories: categories.reduce((acc, category) => {
                const filterVals = fc[category];
                if (filterVals)
                    acc[category] = filterVals.map((value) => {
                        if (appPrefixes[category]) {
                            const prefix = appPrefixes[category];
                            if (prefix === undefined)
                                return value;
                            return transform(prefix, value);
                        }
                        else {
                            return value;
                        }
                    });
                return acc;
            }, {})
        }
    };
};
const reduceState = (state) => {
    return Object.keys(state).reduce((acc, key) => {
        const val = state[key];
        if (val == null)
            return acc;
        if (key === 'mapProps') {
            if (val.rects?.length)
                acc[key] = val;
        }
        else if (Array.isArray(val) && val.length) {
            acc[key] = val;
        }
        else if (typeof val === 'object') {
            const part = reduceState(val);
            if (Object.keys(part).length) {
                acc[key] = part;
            }
        }
        else if (state.route === 'metadata' && key === 'id' && val && val.length > 0) {
            acc[key] = getLastSegmentInUrl(val);
        }
        else if (typeof val === 'string') {
            acc[key] = val;
        }
        else if (typeof val === 'number' && val !== 0) {
            acc[key] = val;
        }
        else if (key === 'showDeprecated' && val) {
            acc[key] = val;
        }
        return acc;
    }, {});
};
export default {
    update,
    updateAndSave,
    serialize,
    deserialize,
    getStateFromHash,
    hashToState,
    hashUpdater,
    storeOverwatch,
    stateToHash,
    shortenUrls,
    extendUrls
};
