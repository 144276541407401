import config from "./config";
import commonConfig from '../../common/main/config';
export const getNewTimeseriesUrl = (items, xAxis, yAxis, y2Axis) => {
    const objIds = items.map((item) => getLastSegmentInUrl(item.dobj)).join();
    return items[0].getNewUrl({
        objId: objIds,
        x: xAxis,
        ...(yAxis && { y: yAxis }),
        ...(y2Axis && { y2: y2Axis }),
        type: 'scatter'
    });
};
export const formatBytes = (bytes, decimals = 2) => {
    if (isNaN(bytes))
        return "";
    if (bytes === 0)
        return '0 Bytes';
    const k = 1024, sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
};
export const varType = (variable) => {
    const isString = typeof variable === 'string';
    if (isString)
        return 'string';
    const isArray = Array.isArray(variable);
    if (isArray)
        return 'array';
    const isPlainObject = variable !== null && typeof variable === 'object' && !Array.isArray(variable);
    if (isPlainObject)
        return 'object';
    return 'unknown';
};
export const isSmallDevice = () => {
    return window.innerWidth <= 768;
};
export const formatDateWithOptionalTime = (d, offset = 0) => {
    // TODO: date can come in as string if it comes from cart view
    if (!d || !d.getUTCFullYear)
        return '';
    d.setUTCHours(d.getUTCHours() + offset);
    const date = `${d.getUTCFullYear()}-${pad2(d.getUTCMonth() + 1)}-${pad2(d.getUTCDate())}`;
    const time = `${pad2(d.getUTCHours())}:${pad2(d.getUTCMinutes())}`;
    return time === "00:00" ? `${date}` : `${date} ${time}`;
};
const pad2 = (s) => {
    return ("0" + s).substr(-2, 2);
};
export const pick = (source, ...keys) => {
    const target = {};
    keys.forEach((key) => {
        if (source.hasOwnProperty(key)) {
            target[key] = source[key];
        }
    });
    return target;
};
export function throwError(msg) {
    throw new Error(msg);
}
export function wholeStringRegExp(anyRegex) {
    const prologue = anyRegex.startsWith('^') ? '' : '^';
    const epilogue = anyRegex.endsWith("$") ? '' : '$';
    return new RegExp([prologue, anyRegex, epilogue].join(''));
}
export function isDefined(x) {
    return x !== undefined;
}
export function getLastSegmentInUrl(url) {
    // Return everything after the last slash (usually an object id) in the URL
    const idx = url.lastIndexOf('/');
    if (idx === -1)
        throw new Error(`Cannot get last segment from '${url}'`);
    return url.substring(idx + 1);
}
export function getLastSegmentsInUrls(urls) {
    // Convert an array of URLs to an array of last URL segments (usually object ids)
    return urls.map(url => getLastSegmentInUrl(url));
}
function getUrlFromPid(pid) {
    return config.objectUriPrefix[config.envri] + pid;
}
export function getUrlsFromPids(pids) {
    return pids.map(pid => getUrlFromPid(pid));
}
export const pidRegexp = /^(?:https?\:\/\/(?:hdl\.handle\.net|doi\.org)\/)?(?:[\d\.]+\d\/)?([a-zA-Z0-9\-_]{24})$/;
export function getUrlWithEnvironmentPrefix(dobj) {
    return commonConfig.metaBaseUri + '/objects/' + dobj.split('/').pop();
}
//converts a regular single-argument function to a function that accepts optional value and returns optional result
export function liftToOptional(f) {
    return io0 => {
        const io = io0;
        if (io === undefined)
            return undefined;
        return f(io);
    };
}
export const linesToShowStyle = (linesToShow) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: linesToShow,
    WebkitBoxOrient: 'vertical'
});
export const areEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length)
        return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i])
            return false;
    }
    return true;
};
export const distinct = (...arrs) => {
    return [...new Set(arrs.flatMap(arr => arr))];
};
export const intersection = (arr1, arr2) => {
    // All elements that are in both arr1 and arr2
    const set2 = new Set(arr2);
    return arr1.filter(val => set2.has(val));
};
export const difference = (arr1, arr2) => {
    // All elements from arr1 that are not in arr2
    const set2 = new Set(arr2);
    return arr1.filter(val => !set2.has(val));
};
export const symmetricalDifference = (arr1, arr2) => {
    // All elements from arr1 that are not in arr2 and all element from arr2 that are not in arr1
    return difference(arr1, arr2).concat(difference(arr2, arr1));
};
export const union = (arr1, arr2) => {
    // All unique elements from arr1 and arr2
    return distinct(arr1, arr2);
};
export const uppercaseFirstChar = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
};
export const round = (num, decimals) => +num.toFixed(decimals);
export function drawRectBoxToCoords(rect) {
    return [
        [rect[0], rect[1]],
        [rect[2], rect[1]],
        [rect[2], rect[3]],
        [rect[0], rect[3]],
        [rect[0], rect[1]]
    ];
}
export const specLabelDisplay = (specLabel) => (config.features.shortenDataTypeLabel && specLabel.includes(','))
    ? specLabel.substring(0, specLabel.indexOf(','))
    : specLabel;
export function stationSpecificTitle(extendedInfo, dataLevel, specLabel) {
    const location = extendedInfo.samplingPoint ?? extendedInfo.site ?? extendedInfo.station?.trim();
    const spec = specLabelDisplay(specLabel);
    const preposition = (config.envri === 'ICOS' && dataLevel > 2) ? "for" : "from";
    return `${spec} ${preposition} ${location}`;
}
