import React, { Component } from 'react';
import { distinct, getLastSegmentInUrl, isDefined, wholeStringRegExp } from '../../utils';
import config, { previewExcludeChartType } from '../../config';
import { previewVarCompare } from "../../models/Preview";
import { lastUrlPart } from 'icos-cp-backend';
import TableFormatCache from '../../../../common/main/TableFormatCache';
import commonConfig, { TIMESERIES } from '../../../../common/main/config';
import PreviewControls from './PreviewControls';
const iFrameBaseUrl = config.iFrameBaseUrl["TIMESERIES"];
export default class PreviewTimeSerie extends Component {
    iframe = null;
    tfCache = new TableFormatCache(commonConfig);
    constructor(props) {
        super(props);
        this.state = {
            tableFormat: undefined
        };
        this.getTableFormat();
    }
    getTableFormat(idx = 0) {
        const preview = this.props.preview;
        if (preview.items.length === 0 || this.tfCache.isInCache(preview.items[idx].spec))
            return;
        this.tfCache.getTableFormat(preview.items[idx].spec)
            .then(tableFormat => this.setState({ tableFormat }));
    }
    handleSelectAction(ev) {
        const { preview, iframeSrcChange, storeTsPreviewSetting, setPreviewYAxis, setPreviewY2Axis } = this.props;
        const { name, selectedIndex, options } = ev.target;
        if ((selectedIndex > 0 || name === 'y2') && iframeSrcChange) {
            if (selectedIndex === 0) {
                preview.item.deleteKeyValPair(name);
            }
            const keyVal = selectedIndex === 0
                ? {}
                : { [name]: options[selectedIndex].value };
            const newUrl = preview.item.getNewUrl(keyVal);
            iframeSrcChange({ target: { src: newUrl } });
            if (this.iframe && this.iframe.contentWindow) {
                this.iframe.contentWindow.postMessage(newUrl, "*");
            }
            if (selectedIndex > 0)
                storeTsPreviewSetting(preview.item.spec, name, options[selectedIndex].value);
            const value = selectedIndex > 0 ? options[selectedIndex].value : undefined;
            if (name === 'y') {
                setPreviewYAxis(value);
            }
            else if (name === 'y2') {
                setPreviewY2Axis(value);
            }
        }
    }
    handleChartTypeAction(currentChartType) {
        const { preview, iframeSrcChange, storeTsPreviewSetting } = this.props;
        const value = currentChartType == 'scatter' ? 'line' : 'scatter';
        const newUrl = preview.item.getNewUrl({ ['type']: value });
        iframeSrcChange({ target: { src: newUrl } });
        if (this.iframe && this.iframe.contentWindow) {
            this.iframe.contentWindow.postMessage(newUrl, "*");
        }
        storeTsPreviewSetting(preview.item.spec, 'type', value);
    }
    shouldComponentUpdate(nextProps, nextState) {
        return !!nextState.tableFormat || (this.props.extendedDobjInfo.length === 0 && nextProps.extendedDobjInfo.length > 0);
    }
    makePreviewOption(actColName) {
        const { preview } = this.props;
        const verbatimMatch = preview.options.find(opt => opt.varTitle === actColName);
        if (verbatimMatch)
            return verbatimMatch;
        const regexMatch = preview.options.find((opt) => testRegexColMatch(opt, actColName));
        if (!regexMatch)
            return; //no preview for columns that are not in portal app's metadata (e.g. flag columns)
        return { ...regexMatch, varTitle: actColName };
    }
    syncTsSettingStoreWithUrl({ xAxis, yAxis, y2Axis, type }, specSettings) {
        const { preview, storeTsPreviewSetting } = this.props;
        if (yAxis && specSettings.y != yAxis)
            storeTsPreviewSetting(preview.item.spec, 'y', yAxis);
        if (xAxis && specSettings.x != xAxis)
            storeTsPreviewSetting(preview.item.spec, 'x', xAxis);
        if (y2Axis && specSettings.y2 != y2Axis)
            storeTsPreviewSetting(preview.item.spec, 'y2', y2Axis);
        if (type && specSettings.type != type)
            storeTsPreviewSetting(preview.item.spec, 'type', type);
    }
    render() {
        const { preview, extendedDobjInfo, tsSettings } = this.props;
        const { tableFormat } = this.state;
        // Add station information
        const items = preview.items.map(cItem => {
            const item = cItem;
            const extendedInfo = extendedDobjInfo.find(ext => ext.dobj === item.dobj);
            item.station = extendedInfo?.station;
            item.stationId = extendedInfo?.stationId;
            item.samplingHeight = extendedInfo?.samplingHeight;
            item.columnNames = extendedInfo?.columnNames;
            return item;
        });
        // Determine if curves should concatenate or overlap
        const linking = items.reduce((acc, curr) => {
            return items.reduce((acc2, curr2) => {
                if ((curr.dobj !== curr2.dobj) &&
                    (curr.station === curr2.station) &&
                    (curr.site && curr2.site && curr.site === curr2.site) &&
                    ((curr.timeEnd < curr2.timeStart) ||
                        (curr.timeStart > curr2.timeEnd)) &&
                    (!curr.samplingHeight || curr.samplingHeight === curr2.samplingHeight)) {
                    return 'concatenate';
                }
                return acc2;
            }, 'overlap');
        }, '');
        const allItemsHaveColumnNames = items.every((cur) => !!(cur.columnNames));
        const legendLabels = extendedDobjInfo.length > 0 ? getLegendLabels(items) : undefined;
        const options = allItemsHaveColumnNames
            ? distinct(items.flatMap(item => item.columnNames ?? []))
                .flatMap(colName => this.makePreviewOption(colName) ?? [])
                .sort(previewVarCompare)
            : preview.options;
        const specSettings = tsSettings[preview.item.spec] || {};
        const { xAxis, yAxis, y2Axis, type } = getAxes(options, preview, specSettings);
        const objIds = preview.items.map((i) => getLastSegmentInUrl(i.dobj)).join();
        const showChartTypeControl = !previewExcludeChartType.datasets.includes(preview.item.dataset);
        const yParam = yAxis ? `&y=${yAxis}` : '';
        const y2Param = y2Axis ? `&y2=${y2Axis}` : '';
        const legendLabelsParams = legendLabels ? `&legendLabels=${legendLabels}` : '';
        const legendLabelsY2Params = y2Axis && legendLabels ? `&legendLabelsY2=${legendLabels}` : '';
        const iframeUrl = `${window.document.location.protocol}//${window.document.location.host}${iFrameBaseUrl}?objId=${objIds}&x=${xAxis}${yParam}${y2Param}&type=${type}&linking=${linking}${legendLabelsParams}${legendLabelsY2Params}`;
        if (!preview)
            return null;
        this.syncTsSettingStoreWithUrl({ xAxis, yAxis, y2Axis, type }, specSettings);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row pb-2 gy-2" },
                React.createElement("div", { className: "col-md-3" },
                    React.createElement(Selector, { name: "y", label: "Y axis", selected: yAxis, options: options, selectAction: this.handleSelectAction.bind(this) })),
                yAxis &&
                    React.createElement("div", { className: "col-md-3" },
                        React.createElement(Selector, { name: "y2", label: "Y2 axis", selected: y2Axis, options: options, selectAction: this.handleSelectAction.bind(this), defaultOptionLabel: "Select a second parameter" })),
                yAxis &&
                    React.createElement(PreviewControls, { iframeUrl: iframeUrl, previewType: TIMESERIES, csvDownloadUrl: csvDownloadUrl(preview.item, tableFormat), chartType: type, chartTypeAction: this.handleChartTypeAction.bind(this), showChartTypeControl: showChartTypeControl })),
            yAxis ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "row mb-4" },
                        React.createElement("div", { className: "col" },
                            React.createElement("div", { style: { position: 'relative', padding: '20%' } },
                                React.createElement("iframe", { ref: iframe => this.iframe = iframe, onLoad: this.props.iframeSrcChange, style: { border: '1px solid #eee', position: 'absolute', top: 5, left: 0, width: '100%', height: '100%' }, src: iframeUrl })))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-3 ms-auto me-auto" },
                            React.createElement(Selector, { name: "x", label: "X axis", selected: xAxis, options: options, selectAction: this.handleSelectAction.bind(this) }))))
                :
                    React.createElement("div", { className: "py-2" })));
    }
}
const getAxes = (options, preview, specSettings) => {
    const getColName = (colName) => {
        const option = options.some((opt) => opt.varTitle === colName);
        return option ? colName : undefined;
    };
    return preview.item && preview.item.hasKeyValPairs
        ? {
            xAxis: preview.item.getUrlSearchValue('x') || getColName(specSettings.x),
            yAxis: preview.item.getUrlSearchValue('y') || getColName(specSettings.y),
            y2Axis: preview.item.getUrlSearchValue('y2') || getColName(specSettings.y2),
            type: specSettings.type || preview.item.getUrlSearchValue('type')
        }
        : { xAxis: undefined, yAxis: undefined, y2Axis: undefined, type: undefined };
};
const Selector = (props) => {
    const value = props.selected ? decodeURIComponent(props.selected) : '0';
    const defaultOptionLabel = props.defaultOptionLabel ?? "Select a parameter";
    const getTxt = (option) => {
        return option.varTitle === option.valTypeLabel
            ? option.varTitle
            : `${option.varTitle}—${option.valTypeLabel}`;
    };
    return (React.createElement("span", null,
        React.createElement("select", { name: props.name, className: "form-select", onChange: props.selectAction, value: value },
            React.createElement("option", { value: "0" }, defaultOptionLabel),
            props.options.map((o, i) => React.createElement("option", { value: o.varTitle, key: props.label.slice(0, 1) + i }, getTxt(o))))));
};
const getLegendLabels = (items) => {
    return items.map(item => item.stationId && item.samplingHeight ? `${item.stationId} ${item.samplingHeight} m Level ${item.level}` : '').join(',');
};
function csvDownloadUrl(item, tableFormat) {
    if (tableFormat === undefined)
        return '';
    const x = item.getUrlSearchValue('x');
    const y = item.getUrlSearchValue('y');
    const y2 = item.getUrlSearchValue('y2');
    if (x === undefined || (y === undefined && y2 === undefined))
        return '';
    const hashId = lastUrlPart(item.dobj);
    const cols = [x, y, y2].filter(isDefined).reduce((acc, colName) => {
        acc.push(colName);
        const flagCol = tableFormat.columns.find(col => col.name === colName)?.flagCol;
        if (flagCol !== undefined)
            acc.push(flagCol);
        return acc;
    }, [])
        .map(col => `col=${col}`).join('&');
    const baseUri = new URL('/csv', document.baseURI).href;
    return `${baseUri}/${hashId}?${cols}`;
}
function testRegexColMatch(opt, actColName) {
    try {
        return wholeStringRegExp(opt.varTitle).test(actColName);
    }
    catch {
        return false;
    }
}
