import React, { Component } from 'react';
import { connect } from 'react-redux';
import CartPanel from '../components/CartPanel';
import { setCartName, fetchIsBatchDownloadOk, updateCheckedObjectsInCart, logCartDownloadClick } from '../actions/cart';
import { formatBytes, getLastSegmentsInUrls } from '../utils';
import { removeFromCart, updateRoute } from "../actions/common";
import Message from '../components/ui/Message';
class DataCart extends Component {
    handlePreview(urls) {
        this.props.updateRoute('preview', getLastSegmentsInUrls(urls));
    }
    handleRouteClick(newRoute) {
        this.props.updateCheckedObjectsInCart([]);
        this.props.updateRoute(newRoute);
    }
    handleAllCheckboxesChange() {
        if (this.props.checkedObjectsInCart.length > 0) {
            this.props.updateCheckedObjectsInCart([]);
        }
        else {
            const checkedObjects = this.props.cart.items.map(item => item.dobj);
            this.props.updateCheckedObjectsInCart(checkedObjects);
        }
    }
    handleFormSubmit() {
        const { name, pids } = this.props.cart;
        logCartDownloadClick(name, pids);
    }
    render() {
        const { preview, user, cart, updateCheckedObjectsInCart } = this.props;
        const previewitemId = preview.item ? preview.item.dobj : undefined;
        const downloadTitle = user.email && user.profile.icosLicenceOk
            ? 'Download cart content'
            : 'Accept license and download cart content';
        const fileName = cart.name;
        const hashes = JSON.stringify(cart.pids);
        return (React.createElement("div", null, !cart.isInitialized || cart.count > 0 ?
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-sm-8 col-lg-9" },
                    React.createElement(CartPanel, { previewitemId: previewitemId, previewItemAction: this.handlePreview.bind(this), updateCheckedObjects: updateCheckedObjectsInCart, handleAllCheckboxesChange: this.handleAllCheckboxesChange.bind(this), ...this.props })),
                React.createElement("div", { className: "col-sm-4 col-lg-3" },
                    React.createElement("div", { className: "card" },
                        React.createElement("div", { className: "card-header" }, downloadTitle),
                        React.createElement("div", { className: "card-body text-center" },
                            React.createElement("form", { action: "/objects", method: "post", onSubmit: this.handleFormSubmit.bind(this), target: "_blank" },
                                React.createElement("input", { type: "hidden", name: "fileName", value: fileName }),
                                React.createElement("input", { type: "hidden", name: "ids", value: hashes }),
                                React.createElement("button", { className: "btn btn-warning", style: { marginBottom: 15, whiteSpace: 'normal' } },
                                    React.createElement("span", { className: "fas fa-download", style: { marginRight: 9 } }),
                                    "Download")),
                            React.createElement("div", { style: { textAlign: 'center', fontSize: '90%' } },
                                "Total size: ",
                                formatBytes(cart.size),
                                " (uncompressed)")))))
            :
                React.createElement(Message, { title: "Your cart is empty", onclick: this.handleRouteClick.bind(this, 'search') })));
    }
}
function stateToProps(state) {
    return {
        cart: state.cart,
        previewLookup: state.previewLookup,
        labelLookup: state.labelLookup,
        user: state.user,
        preview: state.preview,
        checkedObjectsInCart: state.checkedObjectsInCart,
        extendedDobjInfo: state.extendedDobjInfo,
    };
}
function dispatchToProps(dispatch) {
    return {
        updateRoute: (route, previewPids) => dispatch(updateRoute(route, previewPids)),
        setCartName: (newName) => dispatch(setCartName(newName)),
        fetchIsBatchDownloadOk: () => dispatch(fetchIsBatchDownloadOk),
        updateCheckedObjectsInCart: (ids) => dispatch(updateCheckedObjectsInCart(ids)),
        removeFromCart: (ids) => dispatch(removeFromCart(ids)),
    };
}
export default connect(stateToProps, dispatchToProps)(DataCart);
