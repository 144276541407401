import stateUtils from "../models/State";
import { fetchBoostrapData, fetchKnownDataObjects, getCart, getError, fetchJson, saveCart } from "../backend";
import { restoreCart } from "../models/Cart";
import * as Payloads from "../reducers/actionpayloads";
import config from "../config";
import { isInPidFilteringMode } from "../reducers/utils";
import { saveToRestheart } from "../../../common/main/backend";
import CartItem from "../models/CartItem";
import { bootstrapRoute, init, loadApp } from "./main";
import { Filter, Value } from "../models/SpecTable";
import keywordsInfo from "../backend/keywordsInfo";
import { SPECCOL } from "../sparqlQueries";
import commonConfig from '../../../common/main/config';
import { drawRectBoxToCoords, getLastSegmentsInUrls } from "../utils";
import { getProjection, getTransformPointFn } from "icos-cp-ol";
export const failWithError = dispatch => error => {
    dispatch(new Payloads.MiscError(error));
    dispatch(logError(error));
};
function logError(error) {
    return (_, getState) => {
        const state = getState();
        const user = state.user;
        const profile = user.profile;
        const userName = user.email
            ? `${profile.givenName} ${profile.surname}`
            : undefined;
        saveToRestheart({
            error: {
                app: 'portal',
                message: error.message,
                state: JSON.stringify(Object.assign({}, stateUtils.serialize(state), {
                    user: userName,
                    cart: state.cart
                })),
                url: decodeURI(window.location.href)
            }
        });
    };
}
export function updateRoute(route, previewPids) {
    return (dispatch, getState) => {
        // The actual route is registered in state in the bootstrap function for each route
        dispatch(bootstrapRoute(getState().user, route, previewPids));
    };
}
export function getFilters(state, forStatCountsQuery = false) {
    const { tabs, filterTemporal, filterPids, filterNumbers, filterKeywords, searchOptions, specTable, keywords } = state;
    let filters = [];
    filters.push({ category: 'deprecated', allow: searchOptions.showDeprecated });
    if (isInPidFilteringMode(tabs, filterPids)) {
        filters.push({ category: 'pids', pids: filterPids });
    }
    else {
        filters.push({ category: 'pids', pids: null });
        if (filterTemporal.hasFilter) {
            filters = filters.concat(filterTemporal.filters);
        }
        if (varNamesAreFiltered(specTable)) {
            const titles = specTable.getColumnValuesFilter('varTitle');
            if (titles != null) {
                filters.push({ category: 'variableNames', names: titles.filter(Value.isString) });
            }
        }
        if (filterKeywords.length > 0) {
            const dobjKeywords = filterKeywords.filter(kw => keywords.dobjKeywords.includes(kw));
            const kwSpecs = keywordsInfo.lookupSpecs(keywords, filterKeywords);
            let specs = kwSpecs;
            if (!forStatCountsQuery) {
                const specsFilt = specTable.basics.getDistinctColValues(SPECCOL);
                specs = (Filter.and([kwSpecs, specsFilt]) || []).filter(Value.isString);
            }
            filters.push({ category: 'keywords', dobjKeywords, specs });
        }
        const geoFilter = getGeoFilter(state.mapProps);
        if (geoFilter)
            filters.push(geoFilter);
        filters = filters.concat(filterNumbers.validFilters);
    }
    return filters;
}
function getGeoFilter(mapProps) {
    const rects = mapProps.rects;
    if (!rects || rects.length === 0)
        return null;
    const srcEpsgCode = `EPSG:${mapProps.srid}`;
    // Register selected projection is case it's a projection not available by default in Proj4
    getProjection(srcEpsgCode);
    const pointTransformer = getTransformPointFn(srcEpsgCode, "EPSG:4326");
    const coordTransformer = (c) => pointTransformer(c[0], c[1]).join(' ');
    const wktPolygons = rects.map(bbox => {
        const coords = drawRectBoxToCoords(bbox).map(coordTransformer).join(', ');
        return '((' + coords + '))';
    });
    const wktGeo = wktPolygons.length === 1
        ? 'POLYGON ' + wktPolygons[0]
        : 'MULTIPOLYGON (' + wktPolygons.join(', ') + ')';
    return {
        category: 'geo',
        wktGeo
    };
}
export const varNameAffectingCategs = ['variable', 'valType'];
export function varNamesAreFiltered(specTable) {
    return varNameAffectingCategs.some(cat => specTable.getFilter(cat) !== null);
}
export function getBackendTables(filters) {
    return (dispatch) => {
        return fetchBoostrapData(filters).then(allTables => {
            dispatch(new Payloads.BootstrapInfo(allTables));
        }, failWithError(dispatch));
    };
}
export function fetchCart(user) {
    return (dispatch) => {
        return getCart(user.email).then(restheartCart => {
            const cart = restoreCart(restheartCart);
            return dispatch(updateCart(user.email, cart));
        });
    };
}
function updateCart(email, cart) {
    const cartLinks = document.querySelectorAll('.cart-link');
    cartLinks.forEach(link => {
        const num = link.querySelector('.items-number');
        if (num) {
            num.textContent = cart.count.toString();
        }
    });
    return dispatch => saveCart(email, cart).then(() => dispatch(new Payloads.BackendUpdateCart(cart)));
}
export function removeFromCart(ids) {
    return (dispatch, getState) => {
        const state = getState();
        const cart = state.cart.removeItems(ids);
        dispatch(updateCart(state.user.email, cart));
    };
}
export function getKnownDataObjInfo(dobjs, cb) {
    return (dispatch) => {
        fetchKnownDataObjects(dobjs).then(result => {
            dispatch(new Payloads.BackendObjectsFetched(result.rows, true));
            if (cb)
                dispatch(cb);
        }, failWithError(dispatch));
    };
}
export function setPreviewUrl(url) {
    return (dispatch) => {
        dispatch(new Payloads.SetPreviewUrl(url));
    };
}
export function addToCart(ids) {
    return (dispatch, getState) => {
        const { previewLookup, objectsTable, user, cart } = getState();
        if (user.email) {
            const newItems = ids.filter(id => !cart.hasItem(id)).map(id => {
                const objInfo = objectsTable.find(o => o.dobj === id);
                if (objInfo === undefined)
                    throw new Error(`Could not find objTable with id=${id} in ${objectsTable}`);
                const previewType = previewLookup?.forDataObjSpec(objInfo.spec)?.type;
                return new CartItem(objInfo.dobj, objInfo, previewType);
            });
            dispatch(new Payloads.MiscUpdateAddToCart(undefined));
            if (newItems.length > 0) {
                dispatch(updateCart(user.email, cart.addItem(newItems)));
            }
        }
        else {
            dispatch(new Payloads.MiscUpdateAddToCart(getLastSegmentsInUrls(ids)));
            const url = window.location;
            url.hash = stateUtils.stateToHash(getState());
            dispatch(new Payloads.MiscUpdateAddToCart(undefined));
            window.location.href = `${commonConfig.authBaseUri}/login/?targetUrl=${encodeURIComponent(url.href)}`;
        }
    };
}
export function setMetadataItem(id) {
    return (dispatch) => {
        dispatch(new Payloads.BackendObjectMetadataId(id));
        dispatch(fetchMetadataItem(id));
    };
}
function fetchMetadataItem(id) {
    return (dispatch) => {
        fetchJson(`${id}?format=json`).then(metadata => {
            const metadataWithId = { ...metadata, id };
            dispatch(new Payloads.BackendObjectMetadata(metadataWithId));
        });
    };
}
export function restoreFromHistory(historyState) {
    return (dispatch) => {
        const ts = historyState.ts ?? Date.now();
        if (Date.now() - ts < config.historyStateMaxAge) {
            dispatch(new Payloads.MiscRestoreFromHistory(historyState));
            dispatch(addStateMissingInHistory);
        }
        else {
            dispatch(init);
        }
    };
}
const addStateMissingInHistory = (dispatch, getState) => {
    const { route, metadata, id } = getState();
    if (route === 'metadata' && metadata && id !== undefined && metadata.id !== id)
        dispatch(setMetadataItem(id));
};
export function loadFromError(user, errorId) {
    return (dispatch) => {
        getError(errorId).then(response => {
            if (response && response.error && response.error.state) {
                const stateJSON = JSON.parse(response.error.state);
                const objectsTable = stateJSON.objectsTable.map((ot) => {
                    return Object.assign(ot, {
                        submTime: new Date(ot.submTime),
                        timeStart: new Date(ot.timeStart),
                        timeEnd: new Date(ot.timeEnd)
                    });
                });
                const cart = restoreCart({ cart: stateJSON.cart });
                const state = Object.assign({}, stateJSON, { objectsTable, ts: undefined, user: {} });
                dispatch(new Payloads.MiscLoadError(state, cart));
            }
            else {
                dispatch(loadApp(user));
            }
        });
    };
}
