import React from 'react';
import { StepButton } from './StepButton';
import config from "../../config";
import { FileDownload } from './FileDownload';
export const Paging = (props) => {
    const { type, paging, requestStep, searchOptions, getAllFilteredDataObjects, exportQuery } = props;
    const { offset, objCount, pageCount } = paging;
    const minObjs = Math.min(offset + pageCount, objCount);
    const to = minObjs < pageCount
        ? pageCount
        : minObjs;
    const count = pageCount < config.stepsize ? to : objCount;
    const isForwardEnabled = to < count;
    const showDeprecated = searchOptions ? searchOptions.showDeprecated : false;
    if (type === "header") {
        return (React.createElement("div", { className: "card-header bg-transparent" },
            React.createElement("span", { className: "align-middle" },
                React.createElement(CountHeader, { objCount: count, to: to, offset: offset, showDeprecated: showDeprecated }),
                React.createElement(FileDownload, { exportQuery: exportQuery, getAllFilteredDataObjects: getAllFilteredDataObjects, searchResultsCount: count })),
            React.createElement("div", { className: "float-end lh-sm" },
                React.createElement(StepButton, { direction: "step-backward", enabled: offset > 0, onStep: () => requestStep(-1) }),
                React.createElement(StepButton, { direction: "step-forward", enabled: isForwardEnabled, onStep: () => requestStep(1) }))));
    }
    else if (type === "footer") {
        return (React.createElement("div", { className: "card-footer" },
            React.createElement("div", { style: { textAlign: 'right', lineHeight: '1rem' } },
                React.createElement(StepButton, { direction: "step-backward", enabled: offset > 0, onStep: () => { window.scrollTo(0, 0); requestStep(-1); } }),
                React.createElement(StepButton, { direction: "step-forward", enabled: isForwardEnabled, onStep: () => { window.scrollTo(0, 0); requestStep(1); } }))));
    }
    else {
        return null;
    }
};
const CountHeader = ({ objCount, to, offset, showDeprecated }) => {
    const deprecatedTxt = showDeprecated
        ? ' (including deprecated objects)'
        : '';
    const countTxt = !isNaN(to) && !isNaN(objCount)
        ? `Data objects ${objCount === 0 ? 0 : offset + 1} to ${to} of ${objCount.toLocaleString()}${deprecatedTxt}`
        : React.createElement("span", null, "\u00A0");
    return React.createElement("span", null, countTxt);
};
