import React, { Component } from "react";
import { connect } from "react-redux";
import { getFilterHelpInfo, setFilterHelpInfo } from "../../actions/search";
const defaultBtn = 'fas fa-question-circle';
const activeBtn = 'fas fa-times-circle';
const defaultIconStyle = {
    fontSize: 15,
    cursor: 'help',
    padding: 0,
    paddingLeft: 5
};
class HelpButton extends Component {
    handleBtnClick = (event) => {
        const { getFilterHelpInfo, setFilterHelpInfo, name, helpContent } = this.props;
        if (helpContent !== undefined) {
            event.stopPropagation();
            setFilterHelpInfo(name, helpContent);
        }
        else {
            getFilterHelpInfo(name);
        }
    };
    render() {
        const { name, title, helpStorage, url } = this.props;
        const styles = { ...defaultIconStyle, ...(this.props.overrideStyles ?? {}) };
        if (name === undefined || !helpStorage.getHelpItem(name))
            return null;
        const className = helpStorage.isActive(url ?? name) ? activeBtn : defaultBtn;
        return React.createElement("span", { className: className, style: styles, title: title, onClick: this.handleBtnClick.bind(this) });
    }
}
function stateToProps(state) {
    return {
        helpStorage: state.helpStorage
    };
}
function dispatchToProps(dispatch) {
    return {
        getFilterHelpInfo: (name) => dispatch(getFilterHelpInfo(name)),
        setFilterHelpInfo: (name, helpContent) => dispatch(setFilterHelpInfo(name, helpContent)),
    };
}
export default connect(stateToProps, dispatchToProps)(HelpButton);
