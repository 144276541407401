import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllFilteredDataObjects, requestStep, toggleSort, updateCheckedObjectsInSearch } from "../../actions/search";
import { Paging } from "../../components/buttons/Paging";
import CheckAllBoxes from "../../components/controls/CheckAllBoxes";
import Dropdown from "../../components/controls/Dropdown";
import CartBtn from "../../components/buttons/CartBtn";
import PreviewBtn from "../../components/buttons/PreviewBtn";
import HelpButton from "../help/HelpButton";
import SearchResultRegularRow from "../../components/searchResult/SearchResultRegularRow";
import { addingToCartProhibition } from '../../models/CartItem';
import DownloadButton from '../../components/buttons/DownloadButton';
const dropdownLookup = {
    fileName: 'File name',
    size: 'File size',
    timeStart: 'Data start date',
    timeEnd: 'Data end date',
    submTime: 'Submission time'
};
class SearchResultRegular extends Component {
    render() {
        const { preview, objectsTable, previewLookup, paging, sorting, searchOptions, toggleSort, requestStep, labelLookup, checkedObjectsInSearch, extendedDobjInfo, updateCheckedObjects, handlePreview, handleAddToCart, handleAllCheckboxesChange, getAllFilteredDataObjects, exportQuery, user } = this.props;
        const objectText = checkedObjectsInSearch.length <= 1 ? "object" : "objects";
        const checkedUriSet = new Set(checkedObjectsInSearch);
        const checkedObjects = objectsTable.filter(o => checkedUriSet.has(o.dobj));
        return (React.createElement("div", { className: "card" },
            React.createElement(Paging, { searchOptions: searchOptions, type: "header", paging: paging, requestStep: requestStep, getAllFilteredDataObjects: getAllFilteredDataObjects, exportQuery: exportQuery }),
            React.createElement("div", { className: "card-body" },
                React.createElement("div", { className: "panel-srollable-controls d-flex justify-content-between flex-wrap" },
                    React.createElement("div", { className: "d-flex mb-2" },
                        React.createElement(CheckAllBoxes, { checkCount: checkedObjectsInSearch.length, totalCount: paging.pageCount, onChange: handleAllCheckboxesChange, disabled: objectsTable.every(o => !addingToCartProhibition(o).allowCartAdd) }),
                        React.createElement(Dropdown, { isSorter: true, isEnabled: true, selectedItemKey: sorting.varName, isAscending: sorting.ascending, itemClickAction: toggleSort, lookup: dropdownLookup }),
                        checkedObjectsInSearch.length > 0 &&
                            React.createElement("span", { style: { marginLeft: 12, marginTop: 7 } },
                                checkedObjectsInSearch.length,
                                " ",
                                objectText,
                                " selected")),
                    React.createElement("div", { className: "d-flex mb-3" },
                        React.createElement("div", { style: { position: 'relative', top: 7, margin: '0 10px' } },
                            React.createElement(HelpButton, { name: 'preview', title: "View help about Preview", overrideStyles: { paddingLeft: 0 } })),
                        React.createElement(PreviewBtn, { style: { marginRight: 10 }, checkedObjects: checkedObjects, previewLookup: previewLookup, clickAction: handlePreview }),
                        user.email &&
                            React.createElement(CartBtn, { style: { marginRight: 10 }, checkedObjects: checkedObjectsInSearch, clickAction: handleAddToCart, enabled: checkedObjectsInSearch.length > 0, type: 'add' }),
                        React.createElement(DownloadButton, { style: {}, checkedObjects: checkedObjectsInSearch, enabled: checkedObjectsInSearch.length > 0 }))),
                React.createElement("div", null, objectsTable.map((objInfo, i) => {
                    const extendedInfo = extendedDobjInfo.find(ext => ext.dobj === objInfo.dobj);
                    return extendedInfo && (React.createElement(SearchResultRegularRow, { labelLookup: labelLookup, extendedInfo: extendedInfo, preview: preview, objInfo: objInfo, key: 'dobj_' + i, updateCheckedObjects: updateCheckedObjects, isChecked: checkedObjectsInSearch.includes(objInfo.dobj), checkedObjects: checkedObjects, isCartView: false }));
                }))),
            React.createElement(Paging, { searchOptions: undefined, type: "footer", paging: paging, requestStep: requestStep, getAllFilteredDataObjects: getAllFilteredDataObjects, exportQuery: exportQuery })));
    }
}
function stateToProps(state) {
    return {
        previewLookup: state.previewLookup,
        labelLookup: state.labelLookup,
        checkedObjectsInSearch: state.checkedObjectsInSearch,
        objectsTable: state.objectsTable,
        preview: state.preview,
        cart: state.cart,
        paging: state.paging,
        sorting: state.sorting,
        searchOptions: state.searchOptions,
        extendedDobjInfo: state.extendedDobjInfo,
        exportQuery: state.exportQuery,
        user: state.user
    };
}
function dispatchToProps(dispatch) {
    return {
        updateCheckedObjects: (ids) => dispatch(updateCheckedObjectsInSearch(ids)),
        toggleSort: (varName) => dispatch(toggleSort(varName)),
        requestStep: (direction) => dispatch(requestStep(direction)),
        getAllFilteredDataObjects: () => dispatch(getAllFilteredDataObjects()),
    };
}
export default connect(stateToProps, dispatchToProps)(SearchResultRegular);
