import React from 'react';
import HelpButton from '../../containers/help/HelpButton';
import Slider from "../ui/Slider";
export const FilterPanel = props => {
    const { header, children, helpItemName, startCollapsed = false } = props;
    return (React.createElement("div", { className: "card" },
        React.createElement("div", { className: "card-header" },
            React.createElement("span", null, header),
            helpItemName && React.createElement(HelpButton, { name: helpItemName })),
        React.createElement(Slider, { startCollapsed: startCollapsed },
            React.createElement("div", { className: "card-body", style: { paddingTop: 0 } }, children))));
};
