import React, { Component } from 'react';
export default class CheckBtn extends Component {
    render() {
        const onClick = this.props.onClick;
        const title = this.props.title || '';
        const isChecked = this.props.isChecked || false;
        return (React.createElement("span", { title: title },
            React.createElement("input", { ref: this.props.checkRef, className: "form-check-input", type: "checkbox", onChange: onClick, checked: isChecked ?? "checked", disabled: this.props.checkboxDisabled })));
    }
}
