import React, { Component } from 'react';
export default class DownloadButton extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { style, enabled, checkedObjects } = this.props;
        const link = enabled ? getDownloadLink(checkedObjects) : undefined;
        const btnType = !enabled ? 'btn-outline-secondary' : 'btn-warning';
        const className = `btn ${btnType} ${enabled ? "" : "disabled"}`;
        const btnStyle = enabled ? {} : { pointerEvents: 'auto', cursor: 'not-allowed' };
        return (React.createElement("div", { style: style },
            React.createElement("a", { href: link, id: "download-button", className: className, style: btnStyle }, "Download")));
    }
}
const getDownloadLink = (objs) => {
    const dobjIds = objs.map(dobj => dobj.split('/').pop());
    if (dobjIds.length == 1) {
        return `/objects/${dobjIds[0]}`;
    }
    else {
        const ids = encodeURIComponent(JSON.stringify(dobjIds));
        const fileName = encodeURIComponent('My data cart');
        return `/objects?ids=${ids}&fileName=${fileName}`;
    }
};
