import React, { Component } from 'react';
import { connect } from "react-redux";
import PreviewTimeSerie from '../components/preview/PreviewTimeSerie';
import PreviewSelfContained from '../components/preview/PreviewSelfContained';
import config from '../config';
import { Events } from 'icos-cp-utils';
import { addToCart, removeFromCart, setPreviewUrl, updateRoute } from "../actions/common";
import { storeTsPreviewSetting, setPreviewYAxis, setPreviewY2Axis } from "../actions/preview";
import { pick } from "../utils";
import PreviewControls from '../components/preview/PreviewControls';
import Message from '../components/ui/Message';
class Preview extends Component {
    events = null;
    constructor(props) {
        super(props);
        this.state = {
            iframeSrc: '',
        };
        this.events = new Events();
        this.events.addToTarget(window, "message", this.handleIframeSrcChange.bind(this));
    }
    handleIframeSrcChange(event) {
        const iframeSrc = event instanceof MessageEvent
            ? event.data
            : event.target.src;
        if (typeof iframeSrc === "string") {
            this.setState({ iframeSrc });
            this.props.setPreviewUrl(iframeSrc);
        }
    }
    handleSearchRouteClick() {
        this.props.updateRoute('search');
    }
    componentWillUnmount() {
        this.events.clear();
    }
    render() {
        const { HelpSection, preview } = this.props;
        const { iframeSrc } = this.state;
        if (!preview || preview.type === undefined) {
            return (React.createElement(Message, { title: "No data found", onclick: this.handleSearchRouteClick.bind(this) }));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { display: 'inline-block' } }, HelpSection),
                React.createElement(PreviewRoute, { iframeSrcChange: this.handleIframeSrcChange.bind(this), iframeUrl: iframeSrc, ...this.props })));
        }
    }
}
const PreviewRoute = (props) => {
    const previewType = props.preview.type;
    if (previewType === config.TIMESERIES) {
        const tsProps = pick(props, 'preview', 'extendedDobjInfo', 'tsSettings', 'storeTsPreviewSetting', 'setPreviewYAxis', 'setPreviewY2Axis', 'iframeSrcChange');
        return React.createElement(PreviewTimeSerie, { ...tsProps });
    }
    else if (previewType === config.NETCDF || previewType === config.MAPGRAPH || previewType === config.PHENOCAM) {
        const scProps = pick(props, 'preview', 'iframeSrcChange', 'iframeUrl');
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'row pb-3' },
                React.createElement(PreviewControls, { iframeUrl: props.iframeUrl, previewType: previewType })),
            React.createElement(PreviewSelfContained, { ...scProps }));
    }
    else {
        const msg = props.preview.items.length
            ? "This type of preview is not yet implemented"
            : "Fetching data for preview...";
        return React.createElement("div", { className: "card-body" }, msg);
    }
};
function stateToProps(state) {
    return {
        preview: state.preview,
        extendedDobjInfo: state.extendedDobjInfo,
        tsSettings: state.tsSettings,
    };
}
function dispatchToProps(dispatch) {
    return {
        setPreviewUrl: (url) => dispatch(setPreviewUrl(url)),
        storeTsPreviewSetting: (spec, type, val) => dispatch(storeTsPreviewSetting(spec, type, val)),
        setPreviewYAxis: (y) => dispatch(setPreviewYAxis(y)),
        setPreviewY2Axis: (y2) => dispatch(setPreviewY2Axis(y2)),
        addToCart: (ids) => dispatch(addToCart(ids)),
        removeFromCart: (ids) => dispatch(removeFromCart(ids)),
        updateRoute: (route) => dispatch(updateRoute(route)),
    };
}
export default connect(stateToProps, dispatchToProps)(Preview);
