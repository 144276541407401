import React, { Component } from "react";
import HelpButton from "../../containers/help/HelpButton";
import { numericFilterLabels } from "../../config";
import FilterOperationBtn from "../buttons/FilterOperationBtn";
export default class NumberFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            val: undefined,
            isValid: true
        };
    }
    handleChange(ev) {
        const filterNumber = this.props.filterNumber.validate(ev.target.value);
        this.setState({
            isValid: filterNumber.isValid,
            val: filterNumber.txt
        });
        if (filterNumber.isValid) {
            this.props.action(filterNumber);
        }
    }
    resetFilter() {
        const filterNumber = this.props.filterNumber.validate("");
        this.setState({ val: "", isValid: true });
        this.props.action(filterNumber);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.filterNumber.txt !== "" && this.props.filterNumber.txt === "")
            this.setState({ val: undefined, isValid: true });
    }
    render() {
        const { isEnabled = true, filterNumber } = this.props;
        if (!isEnabled)
            return null;
        const { isValid, val } = this.state;
        const style = isValid ? {} : { backgroundColor: '#ebccd1' };
        const value = val ?? filterNumber.txt;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row", style: { marginTop: 10 } },
                React.createElement("div", { className: "col d-flex justify-content-between" },
                    React.createElement("div", null,
                        React.createElement("label", null, numericFilterLabels[filterNumber.category]),
                        React.createElement(HelpButton, { name: filterNumber.category })),
                    React.createElement("div", null,
                        React.createElement(FilterOperationBtn, { enabled: value.length > 0, title: "Reset this filter", baseStyle: { fontSize: 16, marginLeft: 12 }, iconCls: "fas fa-times-circle", action: this.resetFilter.bind(this) })))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-md-12" },
                    React.createElement("input", { type: "text", className: "form-control", onChange: this.handleChange.bind(this), disabled: !isEnabled, style: style, value: value })))));
    }
}
;
